import React from 'react'
import ReactPlayer from 'react-player'
import CardItem from './CardItem'
import './Cards.css'

function Cards() {
    return (
        <div className='cards'>
            {/* <h2> The fastest way in and out of O'Hare Airport.</h2> */}
            <div className="cards__container">
                <div className="cards__wrapper">
                    <ul className="cards__items">

                        <ReactPlayer
                        url='http://youtu.be/Fkq0iAuC5CI'
                        width="100%"
                        controls
                        text="Leave the car at home and let us do the driving."
                        label='Luxury Rides'
                        path='/luxury-rides'
                         />
                    </ul>
                    <ul className="cards__items">
                        <CardItem
                        src="images/tesla-supercharger.jpg"
                        text="Electric Vehicle and Tesla Charging stations available. "
                        label='Tesla Supercharger'
                        path='/greeter'
                         />
                         <CardItem
                        src="images/professional-valet.jpg"
                        text="Outstanding Customer Service."
                        label='Service Gauranteed'
                        path='/greeter'
                         />
                    </ul>
                    
                    <div className="mapouter"><div className="gmap_canvas"><iframe width="100%" height="400" id="gmap_canvas" src="https://maps.google.com/maps?q=Airport%20SpeedPark%20Rosemont&t=&z=13&ie=UTF8&iwloc=&output=embed" frameBorder="0" scrolling="no" marginHeight="0" marginWidth="0"></iframe></div></div>

                </div>
            </div>            
        </div>
    )
}

export default Cards
