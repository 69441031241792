import React, { useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import './Navbar.css';
import { Button } from './Button';



function Navbar() {
    const [click, setClick] = useState(false);
    const [button, setButton] = useState(true);

    const handleClick = () => setClick(!click);
    const closeMobileMenu = () => setClick(false);

    const showButton = () => {
        if(window.innerWidth <= 960) {
            setButton(false);
        } else {
            setButton(true);
        }
    };

    useEffect (() => {
        showButton();
    }, []);

    window.addEventListener('resize', showButton);
    const myRef = useRef(null)

    return (
        <>
          <nav className='navbar'>
              <div className='navbar-container' ref={myRef}>
                  <Link to='/' className='navbar-logo' onClick={closeMobileMenu}>
                     <img src="images/logo-new.png" width="120px" />
                  </Link>
                  <div className='menu-icon' onClick={handleClick}>
                      <i className={click ? 'fas fa-times' : 'fas fa-bars'} />
                  </div>
                  <ul className={click ? 'nav-menu active' : 'nav-menu'}>
                      <li className='nav-item'>
                          <Link to='/' className='nav-links' onClick={closeMobileMenu}>
                              Home
                          </Link>
                      </li>
                      <li className='nav-item'>
                          <Link to='/reserve' className='nav-links' onClick={closeMobileMenu}>
                              Reserve
                          </Link>
                      </li>
                      <li className='nav-item'>
                          <Link to='/how-it-works' className='nav-links' onClick={closeMobileMenu}>
                              How It Works
                          </Link>
                      </li>
                      <li className='nav-item'>
                          <Link to='/faqs' className='nav-links' onClick={closeMobileMenu}>
                              FAQ's
                          </Link>
                      </li>
                  </ul>
                    {/* {button && <Button buttonStyle='btn--outline'>FAQ's</Button>} */}
              </div>
          </nav>

        </>
    )
}

export default Navbar
