import React, { useState, useRef } from "react";
import moment from "moment";
import QRCode from "react-qr-code";
import Container from "@material-ui/core/Container";
import FormHelperText from "@material-ui/core/FormHelperText";
import { makeStyles } from "@material-ui/core/styles";
import Accordion from "@material-ui/core/Accordion";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import FlightLandIcon from "@material-ui/icons/FlightLand";
import PaymentIcon from "@material-ui/icons/Payment";
import WorkOutlineIcon from "@material-ui/icons/WorkOutline";
import SmsOutlinedIcon from "@material-ui/icons/SmsOutlined";
import ConfirmationNumberOutlinedIcon from "@material-ui/icons/ConfirmationNumberOutlined";
import DirectionsCarOutlinedIcon from "@material-ui/icons/DirectionsCarOutlined";
import SmartphoneOutlinedIcon from "@material-ui/icons/SmartphoneOutlined";
import AirportShuttleIcon from "@material-ui/icons/AirportShuttle";
import LocalParkingIcon from "@material-ui/icons/LocalParking";
import FlightTakeoffIcon from "@material-ui/icons/FlightTakeoff";
import SecurityIcon from "@material-ui/icons/Security";
import EmojiTransportationIcon from "@material-ui/icons/EmojiTransportation";
import PermDeviceInformationIcon from "@material-ui/icons/PermDeviceInformation";
import "./Submit.css";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: "33.33%",
    flexShrink: 0,
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
  },
}));
export const Submit = ({ formData }) => {
  var {
    timestamp,
    bookingNo,
    bookingNoString,
    serviceType,
    enterDate,
    exitDate,
    enterTime12,
    enterTime,
    exitTime12,
    exitTime,
    airport,
    airline,
    airportCode,
    emailAddress,
    meetingPoint,
    totalDays = calculateDays(enterDate, exitDate),
  } = formData;

  if(airportCode === "ord") {
    airport = "OHare Intl. Airport";
    }else if(airportCode === "mdw"){
      airport = "Midway Intl. Airport";
    }else{
      airport = "Not Selected"
    }

  // if(airlineCode === "EI") {
  //   airline = "Aer Lingus";
  //   }else if(airlineCode === "AM"){
  //   airline = "Aeromexico";
  //   }else if(airlineCode === "AC"){
  //   airline = "Air Canada";
  //   }else if(airlineCode === "3E"){
  //   airline = "Air Choice One";
  //   }else if(airlineCode === "AF"){
  //   airline = "Air France";
  //   }else if(airlineCode === "AI"){
  //   airline = "Air India";
  //   }else if(airlineCode === "AS"){
  //   airline = "Alaska Airlines";
  //   }else if(airlineCode === "AZ"){
  //   airline = "Alitalia";
  //   }else if(airlineCode === "g4"){
  //   airline = "Allegiant Air";
  //   }else if(airlineCode === "AA"){
  //   airline = "American Airlines";
  //   }else if(airlineCode === "NH"){
  //   airline = "ANA";
  //   }else if(airlineCode === "OZ"){
  //   airline = "Asiana Airlines"
  //   }else if(airlineCode === "OS"){
  //   airline = "Austrian Airlines";
  //   }else if(airlineCode === "TA"){
  //   airline = "Avianca El Salvador";
  //   }else if(airlineCode === "BA"){
  //   airline = "British Airways";
  //   }else if(airlineCode === "CX"){
  //   airline = "Cathay Pacific";
  //   }else if(airlineCode === "KX"){
  //   airline = "Cayman Airways";
  //   }else if(airlineCode === "MU"){
  //   airline = "China Eastern Airlines"
  //   }else if(airlineCode === "CM"){
  //   airline = "Copa Airlines";
  //   }else if(airlineCode === "DL"){
  //   airline = "Delta Air Lines";
  //   }else if(airlineCode === "EK"){
  //   airline = "Emirates";
  //   }else if(airlineCode === "EY"){
  //   airline = "Etihad Airways";
  //   }else if(airlineCode === "BR"){
  //   airline = "EVA Air";
  //   }else if(airlineCode === "AY"){
  //   airline = "Finnair";
  //   }else if(airlineCode === "F9"){
  //   airline = "Frontier Airlines";
  //   }else if(airlineCode === "HU"){
  //   airline = "Hainan Airlines";
  //   }else if(airlineCode === "IB"){
  //   airline = "Iberia";
  //   }else if(airlineCode === "FI"){
  //   airline = "Icelandair";
  //   }else if(airlineCode === "4O"){
  //   airline = "Interjet";
  //   }else if(airlineCode === "JL"){
  //   airline = "Japan Airlines";
  //   }else if(airlineCode === "B6"){
  //   airline = "JetBlue Airways";
  //   }else if(airlineCode === "KL"){
  //   airline = "KLM";
  //   }else if(airlineCode === "KE"){
  //   airline = "Korean Air";
  //   }else if(airlineCode === "LO"){
  //   airline = "LOT Polish Airlines";
  //   }else if(airlineCode === "LH"){
  //   airline = "Lufthansa";
  //   }else if(airlineCode === "pd"){
  //   airline = "Porter Air";
  //   }else if(airlineCode === "QR"){
  //   airline = "Qatar Airways";
  //   }else if(airlineCode === "RJ"){
  //   airline = "Royal Jordanian";
  //   }else if(airlineCode === "SK"){
  //   airline = "Scandinavian Airlines SAS";
  //   }else if(airlineCode === "WN"){
  //   airline = "Southwest Airlines";
  //   }else if(airlineCode === "NK"){
  //   airline = "Spirit Airlines";
  //   }else if(airlineCode === "LX"){
  //   airline = "Swiss International Air Lines";
  //   }else if(airlineCode === "TK"){
  //   airline = "THY Turkish Airlines";
  //   }else if(airlineCode === "UA"){
  //   airline = "United Airlines";
  //   }else if(airlineCode === "VX"){
  //   airline = "Virgin America";
  //   }else if(airlineCode === "VS"){
  //   airline = "Virgin Atlantic";
  //   }else if(airlineCode === "Y4"){
  //   airline = "Volaris";
  //   }else if(airlineCode === "WS"){
  //   airline = "WestJet";
  //   }else{
  //   airline = "Non selected";
  //   }
  //   var airlineCode = airlineCode.toLowerCase();
  
  console.log(totalDays);
  const calculateDays = (enterDate, exitDate) => {
    const start = new Date(enterDate); //clone
    const end = new Date(exitDate); //clone
    let dayCount = 1;
    while (end > start) {
      dayCount++;
      start.setDate(start.getDate() + 1);
    }
    return dayCount;
  };

  calculateDays(enterDate, exitDate);
  const totalDaysCal = calculateDays(enterDate, exitDate);
  console.log("Calculate Days " + calculateDays(enterDate, exitDate));
  const enterDateFormated = moment(enterDate).format("MMM Do YYYY");
  const exitDateFormated = moment(exitDate).format("MMM Do YYYY");

  const classes = useStyles();
  const [expanded, setExpanded] = React.useState("panel1");
  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  var enterTime12 = moment(enterTime, ["HH:mm"]).format("h:mm A");
  var exitTime12 = moment(exitTime, ["HH:mm"]).format("h:mm A");

  const myRef = useRef(null)

  const executeScroll = () => myRef.current.scrollIntoView(0, 0)    
  // run this function from an event handler or an effect to execute scroll

  return (
    <div className="hero-action-container" style={{padding: "15px"}} ref={myRef}>
      <div className="submit-heading">
        <h1>Your Booking is Confirmed.</h1>
        We've sent an email confirmation to {emailAddress}.
      </div>

      <Accordion
        expanded={expanded === "panel1"}
        onChange={handleChange("panel1")}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2bh-content"
          id="panel2bh-header"
          style={{ backgroundColor: "#dea80a" }}
        >
          <ConfirmationNumberOutlinedIcon
            style={{
              verticalAlign: "left",
              marginBottom: "-6px",
              marginRight: "5px",
              paddingRight: "0px",
              color: "white",
              fontSize: "30px",
            }}
          />{" "}
          <Typography className="toKnowHeading"
           onClick={() => {
            executeScroll();
            }}
          >Confirmation</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography align="center">
            <SmartphoneOutlinedIcon style={{ marginBottom: "-6px" }} /> Please
            present to your valet attendent.
            <div className="qrCodeStyle">
              <QRCode value={bookingNoString} size={100} />
            </div>
            <span style={{ paddingBottom: "0px" }}>Booking Number</span>
            <span
              style={{
                color: "rgb(222, 168, 10)",
                fontSize: "40px",
                fontWeight: "strong",
              }}
            >
              <br />
              {bookingNo}
            </span>
            <div className="location-details">
              <div className="location-left">
                <div className="left-border">
                  <FormHelperText>Service Type:</FormHelperText>
                  <span className="location-text">{serviceType}</span>
                  <FormHelperText>Parking Days:</FormHelperText>
                  <span className="location-text">{totalDaysCal}</span>
                </div>
              </div>
              <div className="location-right">
                <div className="right-border">
                  <FormHelperText>Airport:</FormHelperText>
                  <span className="location-text">
                    {airport}
                    <FormHelperText>Meeting Point:</FormHelperText>
                    {meetingPoint}
                  </span>
                </div>
              </div>
            </div>
            <div className="checkin-details">
              <div className="location-left">
                <FormHelperText>Start Date:</FormHelperText>
                <span className="location-text">{enterDateFormated}</span>
              </div>
              <div className="location-right">
                <FormHelperText>Meet Time:</FormHelperText>
                <span className="location-text">{enterTime12}</span>
              </div>
            </div>
            <div className="checkout-details">
              <div className="location-left">
                <FormHelperText>End Date:</FormHelperText>
                <span className="location-text">{exitDateFormated}</span>
              </div>
              <div className="location-right">
                <FormHelperText>Meet Time:</FormHelperText>
                <span className="location-text">{exitTime12}</span>
              </div>
            </div>
          </Typography>
        </AccordionDetails>
      </Accordion>
    
      <div className={classes.root}>
      <Accordion
          expanded={expanded === "panel3"}
          onChange={handleChange("panel3")}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
            style={{ backgroundColor: "#dea80a" }}
          >
            <LocalParkingIcon
              style={{
                verticalAlign: "left",
                marginBottom: "-6px",
                marginRight: "5px",
                paddingRight: "0px",
                color: "white",
                fontSize: "30px",
              }}
            />{" "}
            <Typography className="toKnowHeading"
              onClick={() => {
              executeScroll();
              }}
            > On Parking Day </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <div className="toKnow">
              <div className="toKnowList">
                <ul>
                  <li>
                    {" "}
                    <EmojiTransportationIcon className="confIcons" />
                    <h4 className="confHeadings">Valet Your Vehicle</h4>
                    <div className="returnText">
                      <span style={{ paddingBottom: "5px" }}>
                        Pull up to the main entrance at 10400 W Higgins Rd
                        Rosemont, IL 60018 and our valet attendant will park
                        your vehicle in our indoor secure facility.
                      </span>
                    </div>
                  </li>
                  <li>
                    {" "}
                    <AirportShuttleIcon className="confIcons" />
                    <h4 className="confHeadings">On Demand Shuttle</h4>
                    <div className="returnText">
                      <span style={{ paddingBottom: "5px" }}>
                        Our on demand shuttle will get you to your terminal
                        within 5 minutes.
                      </span>
                    </div>
                  </li>
                  <li>
                    {" "}
                    <FlightTakeoffIcon className="confIcons" />
                    <h4 className="confHeadings">Have s Safe Flight.</h4>
                    <div className="returnText">
                      <span style={{ paddingBottom: "5px" }}>
                        We'll take it from here. Your vehicle will be safe
                        untill you return..
                      </span>
                    </div>
                  </li>
                  <li>
                    {" "}
                    <PermDeviceInformationIcon className="confIcons" />
                    <h4 className="confHeadings">Real Time Surveillance</h4>
                    <div className="returnText">
                      <span style={{ paddingBottom: "5px" }}>
                        We'll send you real time video, images and millage of
                        your vehicle when parked at our indoor parking facility.
                      </span>
                    </div>
                  </li>
                  <li>
                    {" "}
                    <SecurityIcon className="confIcons" />
                    <h4 className="confHeadings">24 Hour Security</h4>
                    <div className="returnText">
                      <span style={{ paddingBottom: "5px" }}>
                        Rest asssure that your vehicle is securely and safely
                        parked.
                      </span>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </AccordionDetails>
        </Accordion>
        <Accordion
          expanded={expanded === "panel2"}
          onChange={handleChange("panel2")}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
            style={{ backgroundColor: "#dea80a" }}
          >
            <FlightLandIcon
              style={{
                verticalAlign: "left",
                marginBottom: "-6px",
                marginRight: "5px",
                paddingRight: "0px",
                color: "white",
                fontSize: "30px",
              }}
            />{" "}
            <Typography 
            className="toKnowHeading"
            onClick={() => {
              executeScroll();
            }} 
            > When You Return </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <div className="toKnow">
              <div className="toKnowList">
                <ul>
                  <li>
                    {" "}
                    <WorkOutlineIcon className="confIcons" />
                    <h4 className="confHeadings">Collect luggage</h4>
                    <div className="returnText">
                      <span style={{ paddingBottom: "5px" }}>
                        Proceed to Baggage Claim and collect any checked bags.
                      </span>
                    </div>
                  </li>
                  <li>
                    {" "}
                    <SmsOutlinedIcon className="confIcons" />
                    <h4 className="confHeadings">Send SMS</h4>
                    <div className="returnText">
                      <span style={{ paddingBottom: "5px" }}>
                        Send us an SMS to 847-447-2212, with you ticket number.{" "}
                        <a href="https://www.w3schools.com">See how.</a>
                      </span>
                    </div>
                  </li>
                  <li>
                    {" "}
                    <DirectionsCarOutlinedIcon className="confIcons" />
                    <h4 className="confHeadings">Proceed to meeting point</h4>
                    <div className="returnText">
                      <span style={{ paddingBottom: "5px" }}>
                        Proceed to your designated meeting point. These
                        locations will vary depending on your airline. You will
                        be notified of this on the day of service.
                      </span>
                    </div>
                  </li>
                  <li>
                    {" "}
                    <PaymentIcon 
                    className="confIcons" 
                    />
                    <h4 className="confHeadings">Pay with your phone</h4>
                    <div className="returnText">
                      <span style={{ paddingBottom: "5px" }}>
                        Pay with your phone while you are waiting for your
                        vehicle
                      </span>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </AccordionDetails>
        </Accordion>
      </div>
    </div>
  );
};