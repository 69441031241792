import React, { useState, useRef } from "react";
import useSpinner from "../Spinner/useSpinner";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Container from "@material-ui/core/Container";
import Button from "@material-ui/core/Button";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import FormHelperText from "@material-ui/core/FormHelperText";
import ErrorOutlineOutlinedIcon from "@material-ui/icons/ErrorOutlineOutlined";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import "./StepForm.css";
import "./Stepper.css";

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    flexWrap: "wrap",
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 200,
  },
}));

export const Vehicle = ({ formData, setForm, navigation }) => {
  const [spinner, showSpinner, hideSpinner] = useSpinner();
  const { plateNo, carMakes, vehicleColor } = formData;

  const [open, setOpen] = React.useState(false);
  const handleClickOpen = () => {
    setOpen(true);
  };
  const validateContactDetails = () => {
    var nextBtn = document.getElementById("nextBtn");
    if (plateNo.length < 2) {
      
      // setEmailError('Enter valid Email!')
    } else {
      // setEmailError('Valid Email :)')
      const handleClickOpen = () => {
        setOpen(false);
        showSpinner();

        setTimeout(() => navigation.next(), 500);
      };

      handleClickOpen();
    }
  };

  const handleClose = () => {
    setOpen(false);
  };

  const myRef = useRef(null)

  const executeScroll = () => myRef.current.scrollIntoView(0, 0)    
  // run this function from an event handler or an effect to execute scroll

  return (
    <div className="hero-action-container" ref={myRef}>
      <h2>Your Vehicle</h2>
      <hr />
      <div className="md-stepper-horizontal orange">
        <div className="md-step active">
          <div className="md-step-circle">
            <span>
              <i className="fas fa-check-circle"></i>
            </span>
          </div>
          <div className="md-step-title">Dates</div>
          <div className="md-step-title">Times</div>
          <div className="md-step-bar-left"></div>
          <div className="md-step-bar-right"></div>
        </div>
        <div className="md-step active">
          <div className="md-step-circle">
            <span>2</span>
          </div>
          <div className="md-step-optional">
            <i className="fas fa-caret-right"></i> Vehicle
          </div>
          <div className="md-step-title"> Return</div>
          <div className="md-step-bar-left"></div>
          <div className="md-step-bar-right"></div>
        </div>
        <div className="md-step">
          <div className="md-step-circle">
            <span>3</span>
          </div>
          <div className="md-step-title">Contact</div>
          <div className="md-step-title">Review</div>
          <div className="md-step-bar-left"></div>
          <div className="md-step-bar-right"></div>
        </div>
      </div>
      {spinner}
      <Dialog
        open={open}
        disableBackdropClick
        disableEscapeKeyDown
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle
          id="form-dialog-title"
          style={{ backgroundColor: "#da3422", color: "white", width: "300px" }}
        >
          <ErrorOutlineOutlinedIcon style={{ marginBottom: "-4px" }} /> Your Vehicle
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            * The following fields are required
            <br />
            <span style={{ color: "black" }}>* Licence Plate</span>
            <br />
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Dismiss
          </Button>
          <Button onClick={() => {}} color="primary"></Button>
        </DialogActions>
      </Dialog>
      <div className="main-content-group">
        <FormHelperText style={{ marginBottom: "-15px" }}>
          Licence Plate #
        </FormHelperText>
        <TextField
          label="My Plate#"
          name="plateNo"
          value={plateNo}
          onChange={setForm}
          margin="normal"
          variant="outlined"
          autoComplete="off"
          fullWidth
        />
        <FormHelperText>Vehicle Make</FormHelperText>
        <Select
          name="carMakes"
          labelId="carMakes"
          id="carMakes"
          value={carMakes}
          displayEmpty
          onChange={setForm}
          variant="outlined"
          fullWidth
        >
          <MenuItem value="" disabled>
            Select Make...
          </MenuItem>
          <MenuItem value="Acura">Acura</MenuItem>
          <MenuItem value="Alfa-Romeo">Alfa-Romeo</MenuItem>
          <MenuItem value="Aston Martin">Aston Martin</MenuItem>
          <MenuItem value="Audi">Audi</MenuItem>
          <MenuItem value="BMW">BMW</MenuItem>
          <MenuItem value="Bentley">Bentley</MenuItem>
          <MenuItem value="Buick">Buick</MenuItem>
          <MenuItem value="Cadilac">Cadilac</MenuItem>
          <MenuItem value="Chevrolet">Chevrolet</MenuItem>
          <MenuItem value="Chrysler">Chrysler</MenuItem>
          <MenuItem value="Daewoo">Daewoo</MenuItem>
          <MenuItem value="Daihatsu">Daihatsu</MenuItem>
          <MenuItem value="Dodge">Dodge</MenuItem>
          <MenuItem value="Eagle">Eagle</MenuItem>
          <MenuItem value="Ferrari">Ferrari</MenuItem>
          <MenuItem value="Fiat">Fiat</MenuItem>
          <MenuItem value="Fisker">Fisker</MenuItem>
          <MenuItem value="Ford">Ford</MenuItem>
          <MenuItem value="Freighliner">Freighliner</MenuItem>
          <MenuItem value="GMC - General Motors Company">
            GMC - General Motors Company
          </MenuItem>
          <MenuItem value="Genesis">Genesis</MenuItem>
          <MenuItem value="Geo">Geo</MenuItem>
          <MenuItem value="Honda">Honda</MenuItem>
          <MenuItem value="Hummer">Hummer</MenuItem>
          <MenuItem value="Hyundai">Hyundai</MenuItem>
          <MenuItem value="Infinity">Infinity</MenuItem>
          <MenuItem value="Isuzu">Isuzu</MenuItem>
          <MenuItem value="Jaguar">Jaguar</MenuItem>
          <MenuItem value="Jeep">Jeep</MenuItem>
          <MenuItem value="Kla">Kla</MenuItem>
          <MenuItem value="Lamborghini">Lamborghini</MenuItem>
          <MenuItem value="Land Rover">Land Rover</MenuItem>
          <MenuItem value="Lexus">Lexus</MenuItem>
          <MenuItem value="Lincoln">Lincoln</MenuItem>
          <MenuItem value="Lotus">Lotus</MenuItem>
          <MenuItem value="Mazda">Mazda</MenuItem>
          <MenuItem value="Maserati">Maserati</MenuItem>
          <MenuItem value="Maybach">Maybach</MenuItem>
          <MenuItem value="McLaren">McLaren</MenuItem>
          <MenuItem value="Mercedez-Benz">Mercedez-Benz</MenuItem>
          <MenuItem value="Mercury">Mercury</MenuItem>
          <MenuItem value="Mini">Mini</MenuItem>
          <MenuItem value="Mitsubishi">Mitsubishi</MenuItem>
          <MenuItem value="Nissan">Nissan</MenuItem>
          <MenuItem value="Oldsmobile">Oldsmobile</MenuItem>
          <MenuItem value="Panoz">Panoz</MenuItem>
          <MenuItem value="Plymouth">Plymouth</MenuItem>
          <MenuItem value="Pontiac">Pontiac</MenuItem>
          <MenuItem value="Porsche">Porsche</MenuItem>
          <MenuItem value="Ram">Ram</MenuItem>
          <MenuItem value="Rolls_Royce">Rolls_Royce</MenuItem>
          <MenuItem value="Saab">Saab</MenuItem>
          <MenuItem value="Saturn">Saturn</MenuItem>
          <MenuItem value="Smart">Smart</MenuItem>
          <MenuItem value="Subaru">Subaru</MenuItem>
          <MenuItem value="Susuki">Susuki</MenuItem>
          <MenuItem value="Tesla">Tesla</MenuItem>
          <MenuItem value="Toyota">Toyota</MenuItem>
          <MenuItem value="Volkswagen">Volkswagen</MenuItem>
          <MenuItem value="Volvo">Volvo</MenuItem>
        </Select>
        <FormHelperText>Color</FormHelperText>
        <Select
          name="vehicleColor"
          labelId="vehicleColor"
          id="vehicleColor"
          value={vehicleColor}
          displayEmpty
          onChange={setForm}
          variant="outlined"
          fullWidth
        >
          <MenuItem value="" disabled>
            Select Color...
          </MenuItem>
          <MenuItem value="Black">Black</MenuItem>
          <MenuItem value="White">White</MenuItem>
          <MenuItem value="Silver">Silver</MenuItem>
          <MenuItem value="Grey">Grey</MenuItem>
          <MenuItem value="Blue">Blue</MenuItem>
          <MenuItem value="Red">Red</MenuItem>
          <MenuItem value="Brown">Brown</MenuItem>
          <MenuItem value="Green">Green</MenuItem>
          <MenuItem value="Other">Other</MenuItem>
        </Select>
      </div>
      <div className="btns-container">
      <Button
        color="default"
        className="left-btn"
        variant="outlined"
        onClick={() => {
          showSpinner();
          setTimeout(() => hideSpinner(), 500);
          setTimeout(() => navigation.previous(), 500);
          executeScroll();
        }}
      >
        Back
      </Button>
      <Button
        color="primary"
        className="center-btn"
        variant="contained"
        onClick={() => {
          // showSpinner();
          // setTimeout(() => hideSpinner(), 500);
          // setTimeout(() => navigation.next(), 500);
          executeScroll();
          handleClickOpen();
          validateContactDetails();
        }}
      >
        Next
      </Button>
      <Button
        color="primary"
        style={{marginTop: "20px"}}
        className="right-btn"
        variant="outlined"
        onClick={() => {
          handleClickOpen();
          validateContactDetails();
          showSpinner();
          setTimeout(() => hideSpinner(), 500);
          setTimeout(() => navigation.next(), 500);
          executeScroll();
        }}
      >
        Skip
      </Button>
      {/* {spinner} */}
      </div>
    </div>
  );
};
