import React, { useState, useRef } from "react";
import moment from "moment";
import useSpinner from "../Spinner/useSpinner";
import "react-datepicker/dist/react-datepicker.css";
import Container from "@material-ui/core/Container";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import FormHelperText from "@material-ui/core/FormHelperText";
import Button from "@material-ui/core/Button";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import { withStyles } from "@material-ui/core/styles";
import MuiAccordion from "@material-ui/core/Accordion";
import MuiAccordionSummary from "@material-ui/core/AccordionSummary";
import MuiAccordionDetails from "@material-ui/core/AccordionDetails";
import DirectionsRunIcon from "@material-ui/icons/DirectionsRun";
import ErrorOutlineOutlinedIcon from "@material-ui/icons/ErrorOutlineOutlined";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import ScheduleOutlinedIcon from "@material-ui/icons/ScheduleOutlined";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import IconButton from "@material-ui/core/IconButton";
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import "./StepForm.css";

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 200,
  },
}));

const Accordion = withStyles({
  root: {
    border: "1px solid rgba(0, 0, 0, .125)",
    boxShadow: "none",
    "&:not(:last-child)": {
      borderBottom: 0,
    },
    "&:before": {
      display: "none",
    },
    "&$expanded": {
      margin: "auto",
    },
  },
  expanded: {},
})(MuiAccordion);

const AccordionSummary = withStyles({
  root: {
    backgroundColor: "rgba(0, 0, 0, .03)",
    borderBottom: "1px solid rgba(0, 0, 0, .125)",
    marginBottom: -1,
    minHeight: 56,
    "&$expanded": {
      minHeight: 56,
    },
  },
  content: {
    "&$expanded": {
      margin: "12px 0",
    },
  },
  expanded: {},
})(MuiAccordionSummary);

const AccordionDetails = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiAccordionDetails);

export const Dates = ({ formData, setForm, navigation }) => {
  const [spinner, showSpinner, hideSpinner] = useSpinner();

  const [open, setOpen] = React.useState(false);

  const validateParkingDates = () => {
    if (enterDate.length < 2 || exitDate.length < 2) {
      console.log("field is less the 2 characters");
    } else {
    

      const handleClickOpen = () => {
        setOpen(true);
      };
      handleClickOpen();
    }
  };
  const handleCloseValidate = () => {
    setOpenValidate(false);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const [openValidate, setOpenValidate] = React.useState(false);
  const validateParkingTimes = () => {
    if (enterTime == "" || exitTime == "") {
    } else {
      const handleClickOpenValidateTimes = () => {
        showSpinner();
        setTimeout(() => hideSpinner(), 1000);
        navigation.next();
      };
      handleClickOpenValidateTimes();
    }
  };

  const [openValidateTimes, setOpenValidateTimes] = React.useState(false);
  const handleCloseValidateTimes = () => {
    setOpenValidateTimes(false);
  };

  const handleClickOpenValidate = () => {
    setOpenValidate(true);
  };

  const handleClickOpenValidateTimes = () => {
    setOpenValidateTimes(true);
  };

  const classes = useStyles();
  

  const [expanded, setExpanded] = React.useState("false");

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  const { go } = navigation;
  var { enterDate, 
        exitDate, 
        enterTime,
        enterTime12, 
        exitTime,
        exitTime12, 
        totalDays,
        dailyRate,
        charges,
        accessFee,
        totalCharge,
         } = formData;


  const [selectedEnterDate, setSelectedEnterDate] = useState(enterDate);
  const [selectedExitDate, setSelectedExitDate] = useState(exitDate);
  
  const [selectedEnterTime, setSelectedEnterTime] = useState(enterTime);
  const [selectedExitTime, setSelectedExitTime] = useState(exitTime);
 
  const handleEnterDate = (e) => {
    setSelectedEnterDate(e.target.value);
    setForm(e);
  };
  const handleExitDate = (e) => {
    setSelectedExitDate(e.target.value);
    setForm(e);
  }
  const handleEnterTime = (e) => {
    setSelectedEnterTime(e.target.value);
    setForm(e);
  };
  const handleExitTime = (e) => {
    setSelectedExitTime(e.target.value);
    setForm(e);
  }

  const calculateDays = (enterDate, exitDate) => {
    const start = new Date(enterDate); //clone
    const end = new Date(exitDate); //clone
    let dayCount = 1;
    while (end > start) {
      dayCount++;
      start.setDate(start.getDate() + 1);
    }
    return dayCount;
  };

  const enterDateFormated = moment(enterDate).format("MMM Do YY");
  const exitDateFormated = moment(exitDate).format("MMM Do YY");
  var totalDays = calculateDays(enterDate, exitDate);
  
  var dailyRate = 22;
  const dailyRateFormated =
    "$" + dailyRate.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
  const dailyRateFixed = dailyRate.toFixed(2);
  var charges = totalDays * dailyRate;
  const chargesFixed = charges;
  const chargesFixedFormated =
    "$" +
    chargesFixed.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
  const stcPercent = 22;
  var accessFee = (chargesFixed * stcPercent) / 100;
  const accessFeeFormated = accessFee.toFixed(2);
  var totalCharge = charges + accessFee;
  const totalValetChargesFixedFormated =
    "$" + totalCharge.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");

  const minStartDate = new Date();
  const minStartDateFormated = moment(minStartDate).format("YYYY-MM-DD");

  const minEndDate = new Date();
  const minEndDateFormated = moment(minEndDate).format("YYYY-MM-DD");

  
  var enterTime12 = moment(enterTime, ["HH:mm"]).format("h:mm A");
  var exitTime12 = moment(exitTime, ["HH:mm"]).format("h:mm A");
  
  console.log(exitTime12);

  const myRef = useRef(null)

   const executeScroll = () => myRef.current.scrollIntoView(0, 0)    
   // run this function from an event handler or an effect to execute scroll

  return (
    
    <div className="hero-action-container" ref={myRef}>
      <h2>Estimated Charges</h2>
      <hr />
      <div className="md-stepper-horizontal orange">
        <div className="md-step active">
          <div className="md-step-circle">
            <span>1</span>
          </div>
          <div className="md-step-optional">
            <i className="fas fa-caret-right"></i> Dates
          </div>
          <div className="md-step-title">Times</div>
          <div className="md-step-bar-left"></div>
          <div className="md-step-bar-right"></div>
        </div>
        <div className="md-step">
          <div className="md-step-circle">
            <span>2</span>
          </div>
          <div className="md-step-title">Vehicle</div>
          <div className="md-step-title">Return</div>
          <div className="md-step-bar-left"></div>
          <div className="md-step-bar-right"></div>
        </div>
        <div className="md-step">
          <div className="md-step-circle">
            <span>3</span>
          </div>
          <div className="md-step-title">Contact</div>
          <div className="md-step-title">Review</div>
          <div className="md-step-bar-left"></div>
          <div className="md-step-bar-right"></div>
        </div>
      </div>
      {spinner}
      <br />
      <form className={classes.container} noValidate>
        <TextField
          id="date"
          name="enterDate"
          label="Start Date"
          type="date"
          required
          variant="outlined"
          format="mm,dd,yyyy"
          readOnly="readOnly"
          onChange={handleEnterDate}
          value={selectedEnterDate}
          // inputProps={{ min: minStartDateFormated, max: "2021-12-31" }}
          className={classes.textField}
          InputLabelProps={{
            shrink: true,
          }}
        />

        <TextField
          id="exitDate"
          name="exitDate"
          label="End Date"
          type="date"
          required
          variant="outlined"
          format="mm,dd,yyyy"
          readOnly="readOnly"
          onChange={handleExitDate}
          value={selectedExitDate}
          // inputProps={{ min: enterDate, max: "2021-12-31" }}
          className={classes.textField}
          InputLabelProps={{
            shrink: true,
          }}
        />

        
      </form>
      <input type="text" name="totalDays" onChange={setForm} value={totalDays} hidden/>
      <input type="text" name="dailyRate" onChange={setForm} value={dailyRate} hidden/>
      <input type="text" name="charges" onChange={setForm} value={charges} hidden/>
      <input type="text" name="accessFee" onChange={setForm} value={accessFee} hidden/>
      <input type="text" name="totalCharge" onChange={setForm} value={totalCharge} hidden/>
      <input type="text" name="enterTime12" onChange={setForm} value={enterTime12} hidden/>
      <input type="text" name="exitTime12" onChange={setForm} value={exitTime12} hidden/>
      <br />
      {/* {spinner} */}

      <Accordion>
        <AccordionSummary aria-controls="panel1a-content" id="panel1a-header">
          <div className="summary-container">
            <div className="left-one">Valet</div>
            <div className="right-one">Charges</div>
            <div className="right-two">Days</div>
            <div className="left-one-text">
              <DirectionsRunIcon />
            </div>
            <div className="right-two-text">
              {totalValetChargesFixedFormated}
            </div>
            <div className="right-one-text">{totalDays}</div>
            <div style={{verticalAlign: "bottom", fontWeight: "200"}}>Show Details {<ExpandMoreIcon style={{verticalAlign: "bottom", marginBottom: "-3px"}}/>}</div>
          </div>
        </AccordionSummary>
        <AccordionDetails>
          <div className="quote-content">
            <div className="quote-summary">
              <span className="left">
                <span className="lite-text">
                  Start: <br />
                </span>{" "}
                {enterDateFormated}
              </span>
              <span className="right">
                <span className="lite-text">
                  End: <br />
                </span>{" "}
                {exitDateFormated}
              </span>
              <IconButton
                color="primary"
                component="span"
                onClick={() => go("dates")}
              ></IconButton>
            </div>
            <div className="quote-calculations">
              <div className="pricing-left">
                Subtotal ({totalDays} X {dailyRateFormated} Day)
              </div>

              <div className="pricing-right" name="charges">
                {chargesFixedFormated}
              </div>

              <div className="pricing-left">Airport Fee</div>
              <div className="pricing-right" name="accessFee">
                {accessFeeFormated}
              </div>
              <div className="pricing-left">Total Charges</div>
              <div className="pricing-right" name="totalCharge">
                {totalValetChargesFixedFormated}
              </div>

              <div className="pricing-message-return">Due Now</div>
              <div className="pricing-total" name="totalCharge">
                $0.0
              </div>
              <div className="pricing-message-return total">Due on Return</div>
              <div className="pricing-total-return" name="totalCharge">
                {totalValetChargesFixedFormated}
              </div>
            </div>
          </div>
        </AccordionDetails>
      </Accordion>
      <br />
      <Dialog
        open={openValidate}
        disableBackdropClick
        disableEscapeKeyDown
        onClose={handleCloseValidate}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle
          id="form-dialog-title"
          style={{ backgroundColor: "#da3422", color: "white", width: "300px" }}
        >
          <ErrorOutlineOutlinedIcon style={{ marginBottom: "-4px" }} /> Parking
          Dates
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            * The following fields are required
            <br />
            <span style={{ color: "black" }}>* Start Date</span>
            <br />
            <span style={{ color: "black" }}>* End Date</span>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseValidate} color="primary">
            Dismiss
          </Button>
          <Button onClick={() => {}} color="primary"></Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={openValidateTimes}
        disableBackdropClick
        disableEscapeKeyDown
        onClose={handleCloseValidateTimes}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle
          id="form-dialog-title"
          style={{ backgroundColor: "#da3434", color: "white", width: "300px" }}
        >
          {" "}
          <ErrorOutlineOutlinedIcon style={{ marginBottom: "-4px" }} /> Parking
          Times
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            * The following fields are required
            <br />
            <span style={{ color: "black" }}>* Start Time</span>
            <br />
            <span style={{ color: "black" }}>* End Time</span>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseValidateTimes} color="primary">
            Dismiss
          </Button>
          <Button onClick={() => {}} color="primary"></Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={open}
        disableBackdropClick
        disableEscapeKeyDown
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle
          id="form-dialog-title"
          style={{ backgroundColor: "#ffc107" }}
        >
          <ScheduleOutlinedIcon style={{ marginBottom: "-4px" }} /> Parking
          Times
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            Select your parking start and end times.
          </DialogContentText>
          <div className="valet-time-container">
            {enterDateFormated}
            <FormHelperText>Start Time</FormHelperText>
            <Select
              name="enterTime"
              labelId="start-time-label"
              id="start-time"
              value={selectedEnterTime}
              displayEmpty
              onChange={handleEnterTime}
              variant="outlined"
              fullWidth
            >
              <MenuItem value="" disabled>
                Start time...
              </MenuItem>
              <MenuItem value='00:00'>12:00 AM </MenuItem>
              <MenuItem value='00:15'>12:15 AM</MenuItem>
              <MenuItem value='00:30'>12:30 AM</MenuItem>
              <MenuItem value='00:45'>12:45 AM</MenuItem>
              <MenuItem value='01:00'>1:00 AM</MenuItem>
              <MenuItem value='01:15'>1:15 AM</MenuItem>
              <MenuItem value='01:30'>1:30 AM</MenuItem>
              <MenuItem value='01:45'>1:45 AM</MenuItem>
              <MenuItem value='02:00'>2:00 AM</MenuItem>
              <MenuItem value='02:15'>2:15 AM</MenuItem>
              <MenuItem value='02:30'>2:30 AM</MenuItem>
              <MenuItem value='02:45'>2:45 AM</MenuItem>
              <MenuItem value='03:00'>3:00 AM</MenuItem>
              <MenuItem value='03:15'>3:15 AM</MenuItem>
              <MenuItem value='03:30'>3:30 AM</MenuItem>
              <MenuItem value='03:45'>3:45 AM</MenuItem>
              <MenuItem value='04:00'>4:00 AM</MenuItem>
              <MenuItem value='04:15'>4:15 AM</MenuItem>
              <MenuItem value='04:30'>4:30 AM</MenuItem>
              <MenuItem value='04:45'>4:45 AM</MenuItem>
              <MenuItem value='05:00'>5:00 AM</MenuItem>
              <MenuItem value='05:15'>5:15 AM</MenuItem>
              <MenuItem value='05:30'>5:30 AM</MenuItem>
              <MenuItem value='05:45'>5:45 AM</MenuItem>
              <MenuItem value='06:00'>6:00 AM</MenuItem>
              <MenuItem value='06:15'>6:15 AM</MenuItem>
              <MenuItem value='06:30'>6:30 AM</MenuItem>
              <MenuItem value='06:45'>6:45 AM</MenuItem>
              <MenuItem value='07:00'>7:00 AM</MenuItem>
              <MenuItem value='07:15'>7:15 AM</MenuItem>
              <MenuItem value='07:30'>7:30 AM</MenuItem>
              <MenuItem value='07:45'>7:45 AM</MenuItem>
              <MenuItem value='08:00'>8:00 AM</MenuItem>
              <MenuItem value='08:15'>8:15 AM</MenuItem>
              <MenuItem value='08:30'>8:30 AM</MenuItem>
              <MenuItem value='08:45'>8:45 AM</MenuItem>
              <MenuItem value='09:00'>9:00 AM</MenuItem>
              <MenuItem value='09:15'>9:15 AM</MenuItem>
              <MenuItem value='09:30'>9:30 AM</MenuItem>
              <MenuItem value='09:45'>9:45 AM</MenuItem>
              <MenuItem value='10:00'>10:00 AM</MenuItem>
              <MenuItem value='10:15'>10:15 AM</MenuItem>
              <MenuItem value='10:30'>10:30 AM</MenuItem>
              <MenuItem value='10:45'>10:45 AM</MenuItem>
              <MenuItem value='11:00'>11:00 AM</MenuItem>
              <MenuItem value='11:15'>11:15 AM</MenuItem>
              <MenuItem value='11:30'>11:30 AM</MenuItem>
              <MenuItem value='11:45'>11:45 AM</MenuItem>
              <MenuItem value='12:00'>12:00 PM</MenuItem>
              <MenuItem value='12:15'>12:15 PM</MenuItem>
              <MenuItem value='12:30'>12:30 PM</MenuItem>
              <MenuItem value='12:45'>12:45 PM</MenuItem>
              <MenuItem value='13:00'>1:00 PM</MenuItem>
              <MenuItem value='13:15'>1:15 PM</MenuItem>
              <MenuItem value='13:30'>1:30 PM</MenuItem>
              <MenuItem value='13:45'>1:45 PM</MenuItem>
              <MenuItem value='14:00'>2:00 PM</MenuItem>
              <MenuItem value='14:15'>2:15 PM</MenuItem>
              <MenuItem value='14:30'>2:30 PM</MenuItem>
              <MenuItem value='14:45'>2:45 PM</MenuItem>
              <MenuItem value='15:00'>3:00 PM</MenuItem>
              <MenuItem value='15:15'>3:15 PM</MenuItem>
              <MenuItem value='15:30'>3:30 PM</MenuItem>
              <MenuItem value='15:45'>3:45 PM</MenuItem>
              <MenuItem value='16:00'>4:00 PM</MenuItem>
              <MenuItem value='16:15'>4:15 PM</MenuItem>
              <MenuItem value='16:30'>4:30 PM</MenuItem>
              <MenuItem value='16:45'>4:45 PM</MenuItem>
              <MenuItem value='17:00'>5:00 PM</MenuItem>
              <MenuItem value='17:15'>5:15 PM</MenuItem>
              <MenuItem value='17:30'>5:30 PM</MenuItem>
              <MenuItem value='17:45'>5:45 PM</MenuItem>
              <MenuItem value='18:00'>6:00 PM</MenuItem>
              <MenuItem value='18:15'>6:15 PM</MenuItem>
              <MenuItem value='18:30'>6:30 PM</MenuItem>
              <MenuItem value='18:45'>6:45 PM</MenuItem>
              <MenuItem value='19:00'>7:00 PM</MenuItem>
              <MenuItem value='19:15'>7:15 PM</MenuItem>
              <MenuItem value='19:30'>7:30 PM</MenuItem>
              <MenuItem value='19:45'>7:45 PM</MenuItem>
              <MenuItem value='20:00'>8:00 PM</MenuItem>
              <MenuItem value='20:15'>8:15 PM</MenuItem>
              <MenuItem value='20:30'>8:30 PM</MenuItem>
              <MenuItem value='20:45'>8:45 PM</MenuItem>
              <MenuItem value='21:00'>9:00 PM</MenuItem>
              <MenuItem value='21:15'>9:15 PM</MenuItem>
              <MenuItem value='21:30'>9:30 PM</MenuItem>
              <MenuItem value='21:45'>9:45 PM</MenuItem>
              <MenuItem value='22:00'>10:00 PM</MenuItem>
              <MenuItem value='22:15'>10:15 PM</MenuItem>
              <MenuItem value='22:30'>10:30 PM</MenuItem>
              <MenuItem value='22:45'>10:45 PM</MenuItem>
              <MenuItem value='23:00'>11:00 PM</MenuItem>
              <MenuItem value='23:15'>11:15 PM</MenuItem>
              <MenuItem value='23:30'>11:30 PM</MenuItem>
              <MenuItem value='23:45'>11:45 PM</MenuItem>
            </Select>
          </div>
          <div className="return-time-container">
            {exitDateFormated}
            <FormHelperText>End Time</FormHelperText>
            <Select
              name="exitTime"
              type="time"
              labelId="end-time-label"
              id="end-time-required"
              value={selectedExitTime}
              displayEmpty
              onChange={handleExitTime}
              variant="outlined"
              fullWidth
            >
              <MenuItem value="" disabled>
                End time...
              </MenuItem>
              <MenuItem value='00:00'>12:00 AM </MenuItem>
              <MenuItem value='00:15'>12:15 AM</MenuItem>
              <MenuItem value='00:30'>12:30 AM</MenuItem>
              <MenuItem value='00:45'>12:45 AM</MenuItem>
              <MenuItem value='01:00'>1:00 AM</MenuItem>
              <MenuItem value='01:15'>1:15 AM</MenuItem>
              <MenuItem value='01:30'>1:30 AM</MenuItem>
              <MenuItem value='01:45'>1:45 AM</MenuItem>
              <MenuItem value='02:00'>2:00 AM</MenuItem>
              <MenuItem value='02:15'>2:15 AM</MenuItem>
              <MenuItem value='02:30'>2:30 AM</MenuItem>
              <MenuItem value='02:45'>2:45 AM</MenuItem>
              <MenuItem value='03:00'>3:00 AM</MenuItem>
              <MenuItem value='03:15'>3:15 AM</MenuItem>
              <MenuItem value='03:30'>3:30 AM</MenuItem>
              <MenuItem value='03:45'>3:45 AM</MenuItem>
              <MenuItem value='04:00'>4:00 AM</MenuItem>
              <MenuItem value='04:15'>4:15 AM</MenuItem>
              <MenuItem value='04:30'>4:30 AM</MenuItem>
              <MenuItem value='04:45'>4:45 AM</MenuItem>
              <MenuItem value='05:00'>5:00 AM</MenuItem>
              <MenuItem value='05:15'>5:15 AM</MenuItem>
              <MenuItem value='05:30'>5:30 AM</MenuItem>
              <MenuItem value='05:45'>5:45 AM</MenuItem>
              <MenuItem value='06:00'>6:00 AM</MenuItem>
              <MenuItem value='06:15'>6:15 AM</MenuItem>
              <MenuItem value='06:30'>6:30 AM</MenuItem>
              <MenuItem value='06:45'>6:45 AM</MenuItem>
              <MenuItem value='07:00'>7:00 AM</MenuItem>
              <MenuItem value='07:15'>7:15 AM</MenuItem>
              <MenuItem value='07:30'>7:30 AM</MenuItem>
              <MenuItem value='07:45'>7:45 AM</MenuItem>
              <MenuItem value='08:00'>8:00 AM</MenuItem>
              <MenuItem value='08:15'>8:15 AM</MenuItem>
              <MenuItem value='08:30'>8:30 AM</MenuItem>
              <MenuItem value='08:45'>8:45 AM</MenuItem>
              <MenuItem value='09:00'>9:00 AM</MenuItem>
              <MenuItem value='09:15'>9:15 AM</MenuItem>
              <MenuItem value='09:30'>9:30 AM</MenuItem>
              <MenuItem value='09:45'>9:45 AM</MenuItem>
              <MenuItem value='10:00'>10:00 AM</MenuItem>
              <MenuItem value='10:15'>10:15 AM</MenuItem>
              <MenuItem value='10:30'>10:30 AM</MenuItem>
              <MenuItem value='10:45'>10:45 AM</MenuItem>
              <MenuItem value='11:00'>11:00 AM</MenuItem>
              <MenuItem value='11:15'>11:15 AM</MenuItem>
              <MenuItem value='11:30'>11:30 AM</MenuItem>
              <MenuItem value='11:45'>11:45 AM</MenuItem>
              <MenuItem value='12:00'>12:00 PM</MenuItem>
              <MenuItem value='12:15'>12:15 PM</MenuItem>
              <MenuItem value='12:30'>12:30 PM</MenuItem>
              <MenuItem value='12:45'>12:45 PM</MenuItem>
              <MenuItem value='13:00'>1:00 PM</MenuItem>
              <MenuItem value='13:15'>1:15 PM</MenuItem>
              <MenuItem value='13:30'>1:30 PM</MenuItem>
              <MenuItem value='13:45'>1:45 PM</MenuItem>
              <MenuItem value='14:00'>2:00 PM</MenuItem>
              <MenuItem value='14:15'>2:15 PM</MenuItem>
              <MenuItem value='14:30'>2:30 PM</MenuItem>
              <MenuItem value='14:45'>2:45 PM</MenuItem>
              <MenuItem value='15:00'>3:00 PM</MenuItem>
              <MenuItem value='15:15'>3:15 PM</MenuItem>
              <MenuItem value='15:30'>3:30 PM</MenuItem>
              <MenuItem value='15:45'>3:45 PM</MenuItem>
              <MenuItem value='16:00'>4:00 PM</MenuItem>
              <MenuItem value='16:15'>4:15 PM</MenuItem>
              <MenuItem value='16:30'>4:30 PM</MenuItem>
              <MenuItem value='16:45'>4:45 PM</MenuItem>
              <MenuItem value='17:00'>5:00 PM</MenuItem>
              <MenuItem value='17:15'>5:15 PM</MenuItem>
              <MenuItem value='17:30'>5:30 PM</MenuItem>
              <MenuItem value='17:45'>5:45 PM</MenuItem>
              <MenuItem value='18:00'>6:00 PM</MenuItem>
              <MenuItem value='18:15'>6:15 PM</MenuItem>
              <MenuItem value='18:30'>6:30 PM</MenuItem>
              <MenuItem value='18:45'>6:45 PM</MenuItem>
              <MenuItem value='19:00'>7:00 PM</MenuItem>
              <MenuItem value='19:15'>7:15 PM</MenuItem>
              <MenuItem value='19:30'>7:30 PM</MenuItem>
              <MenuItem value='19:45'>7:45 PM</MenuItem>
              <MenuItem value='20:00'>8:00 PM</MenuItem>
              <MenuItem value='20:15'>8:15 PM</MenuItem>
              <MenuItem value='20:30'>8:30 PM</MenuItem>
              <MenuItem value='20:45'>8:45 PM</MenuItem>
              <MenuItem value='21:00'>9:00 PM</MenuItem>
              <MenuItem value='21:15'>9:15 PM</MenuItem>
              <MenuItem value='21:30'>9:30 PM</MenuItem>
              <MenuItem value='21:45'>9:45 PM</MenuItem>
              <MenuItem value='22:00'>10:00 PM</MenuItem>
              <MenuItem value='22:15'>10:15 PM</MenuItem>
              <MenuItem value='22:30'>10:30 PM</MenuItem>
              <MenuItem value='22:45'>10:45 PM</MenuItem>
              <MenuItem value='23:00'>11:00 PM</MenuItem>
              <MenuItem value='23:15'>11:15 PM</MenuItem>
              <MenuItem value='23:30'>11:30 PM</MenuItem>
              <MenuItem value='23:45'>11:45 PM</MenuItem>
            </Select>
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseValidateTimes} color="primary"></Button>
          <Button
            onClick={() => {
              validateParkingTimes();
              handleClickOpenValidateTimes();
              executeScroll();
            }}
            color="primary"
          >
            Save
          </Button>
        </DialogActions>
      </Dialog>
      <Button
        variant="contained"
        fullWidth
        style={{height: "55px", marginRight: "1rem", marginBottom: "1rem", fontSize: "18px" }}
        color="primary"
        onClick={() => {
          handleClickOpenValidate();
          validateParkingDates();
          executeScroll();
        }}
      >
        RESERVE
      </Button>
    </div>
  );
};

