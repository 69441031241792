import React, { useState, useRef } from "react";
import moment from "moment";
import useSpinner from "../Spinner/useSpinner";
import "react-datepicker/dist/react-datepicker.css";
import Button from "@material-ui/core/Button";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import ErrorOutlineOutlinedIcon from "@material-ui/icons/ErrorOutlineOutlined";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import ScheduleOutlinedIcon from "@material-ui/icons/ScheduleOutlined";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import IconButton from "@material-ui/core/IconButton";
import "./Action.css";
import Cards from "../Cards";
const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 200,
  },
}));



export const Action = ({ formData, setForm, navigation }) => {
  const [spinner, showSpinner, hideSpinner] = useSpinner();

  const [open, setOpen] = React.useState(false);

  const validateParkingDates = () => {
    if (enterDate.length < 2 || exitDate.length < 2) {
      console.log("field is less the 2 characters");
    } else {
      
      const handleClickOpenValidate = () => {
        showSpinner();
        setTimeout(() => hideSpinner(), 1500);
        setOpenValidate(false);
        setTimeout(() => navigation.next(), 1500);
      };
      handleClickOpenValidate();
    }
  };
  const handleCloseValidate = () => {
    setOpenValidate(false);
  };
  
  // const [showSections, setShowSections] = React.useState(true)
  // const onClick = () => setShowSections(false)

  const [openValidate, setOpenValidate] = React.useState(false);

  const handleClickOpenValidate = () => {
    setOpenValidate(true);
  };

  const classes = useStyles();
  const [selectedEnterDate, setSelectedEnterDate] = useState(new Date());
  const [selectedExitDate, setSelectedExitDate] = useState(new Date());
  const [expanded, setExpanded] = React.useState("false");

  const { go } = navigation;
  const { enterDate, exitDate} = formData;
  const minStartDate = new Date();
  const minStartDateFormated = moment(minStartDate).format("YYYY-MM-DD");

  const minEndDate = new Date();
  const minEndDateFormated = moment(minEndDate).format("YYYY-MM-DD");
  
  const myRef = useRef(null)

  const executeScroll = () => myRef.current.scrollIntoView(0, 0);    
  // run this function from an event handler or an effect to execute scroll
  return (
    <div className="hero-action-container" ref={myRef}>
      <h2>Reserve with 3 easy steps!</h2>
      <p className="actionp">Its fast and easy! <span style={{color: "green"}}>No Credit card required.</span> You pay when you return.</p>
      {spinner}
      <TextField
          style={{height: "55px", marginRight: "1rem", marginBottom: "1rem", marginTop: "1rem" }}
          id="airport"
          name="airport"
          label="Airport"
          type="text"
          value="OHare Intl. Airport"
          readOnly
          required
          fullWidth
          variant="outlined"
        //   onChange={setForm}
        //   value={startDate}
        />
      <TextField
          style={{height: "55px", marginRight: "1rem", marginBottom: "1rem" }}
          id="enterDate"
          name="enterDate"
          label="Start Date"
          type="date"
          value={enterDate}
          required
          variant="outlined"
          format="mm,dd,yyyy"
          onChange={setForm}
          value={enterDate}
          
          inputProps={{ min: minEndDateFormated, max: "2021-12-31" }}
        //   className={classes.textField}
          InputLabelProps={{
          shrink: true,
          }}
          fullWidth
        />
        <TextField
          style={{height: "55px", marginRight: "1rem", marginBottom: "1rem" }}
          className="exitDate"
          id="exitDate"
          name="exitDate"
          label="End Date"
          type="date"
          value={exitDate}
          required
          variant="outlined"
          format="mm,dd,yyyy"
          
          onChange={setForm}
          value={exitDate}
          inputProps={{ min: enterDate, max: "2021-12-31" }}
        //   className={classes.textField}
          InputLabelProps={{
          shrink: true,
          }}
          fullWidth
          // style={{ marginBottom: "1rem", marginRight: "1rem", width: "300px" }}
        />
       <Button
       fullWidth
        style={{height: "55px", marginRight: "1rem", marginBottom: "1rem" }}
        variant="contained"
        color="primary"
        onClick={() => {
          handleClickOpenValidate();
          validateParkingDates();
          executeScroll();
          // onClick();
        }}
      >
        
        <br />
        <span style={{ fontSize: "18px" }}>GET RATES</span>
      </Button>
      <Dialog
        open={openValidate}
        disableBackdropClick
        disableEscapeKeyDown
        onClose={handleCloseValidate}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle
          id="form-dialog-title"
          style={{ backgroundColor: "#da3422", color: "white", width: "300px" }}
        >
          <ErrorOutlineOutlinedIcon style={{ marginBottom: "-4px" }} /> Parking
          Dates
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            * The following fields are required
            <br />
            <span style={{ color: "black" }}>* Start Date</span>
            <br />
            <span style={{ color: "black" }}>* End Date</span>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseValidate} color="primary">
            Dismiss
          </Button>
          <Button onClick={() => {}} color="primary"></Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};