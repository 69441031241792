import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import MuiAccordion from '@material-ui/core/Accordion';
import MuiAccordionSummary from '@material-ui/core/AccordionSummary';
import MuiAccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import "./Faqs.css";

const Accordion = withStyles({
  root: {
    border: '1px solid rgba(0, 0, 0, .125)',
    boxShadow: 'none',
    '&:not(:last-child)': {
      borderBottom: 0,
    },
    '&:before': {
      display: 'none',
    },
    '&$expanded': {
      margin: 'auto',
    },
  },
  expanded: {},
})(MuiAccordion);
// rgb(218 170 78);
const AccordionSummary = withStyles({
  root: {
    backgroundColor: 'rgba(218, 170, 78)',
    borderBottom: '1px solid rgba(0, 0, 0, .125)',
    marginBottom: -1,
    minHeight: 56,
    '&$expanded': {
      minHeight: 56,
    },
  },
  content: {
    '&$expanded': {
      margin: '12px 0',
    },
  },
  expanded: {},
})(MuiAccordionSummary);

const AccordionDetails = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiAccordionDetails);

export default function Faqs() {
  const [expanded, setExpanded] = React.useState('panel1');

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  return (
    <div className="faq-container">
      <h1>Frequently Asked Questions</h1>
      
      <Accordion square expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
        <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
          <Typography>How does Airport SpeedPark Work?</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
          We make parking the most convenient and fastest way in and out of Chicago O'Hare International Airport. Customers stop momentarily curbside of our office located at 10400 W Higgins Rd. (less than 5 minutes from the airport terminal). Our on demand luxury shuttle then drives you to your departing terminal. Your vehicle is returned to you at the terminal when you return.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion square expanded={expanded === 'panel2'} onChange={handleChange('panel2')}>
        <AccordionSummary aria-controls="panel2d-content" id="panel2d-header">
          <Typography>Is Airport SpeedPark licenced and insured?</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography> 

We carry a 2 million dollar Garage Keeper Legal Liability Insurance Policy, which is an "A XV" Class Insurance Policy. And, when we provide valet service for you, you are named as an additional insured on our policy - protecting you from any claims arising from our service.
Although you will be faxed verification of your coverage, we encourage you to call our insurance provider to validate our insurance standings. We want you to have complete peace of mind.
{/* In addition to liability insurance for your guest's cars, Airport SpeedPark carries workmen's compensation insurance on all of our employees. Should one of them be injured on your premises, they're covered under their employment status. Of course we also comply with all applicable State and Federal withholding and employer payment requirements. */}

          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion square expanded={expanded === 'panel3'} onChange={handleChange('panel3')}>
        <AccordionSummary aria-controls="panel3d-content" id="panel3d-header">
          <Typography>Is your parking facility secure?</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            Our parking facility is secured and moniterd 24 hours a day.
          </Typography>
        </AccordionDetails>
      </Accordion>
      
    </div>
  );
}
