import React from 'react';
import SpinnerIcon from './SpinnerIcon';

const Spinner = props => {
    return (
        <div className="Spinner">
            <SpinnerIcon />
            
        </div>
    );
};

export default Spinner;