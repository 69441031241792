import React from 'react';
import { Button } from './Button';
import './Footer.css';

function Footer() {
    return (
        <div className='footer-container'>
            <section className="footer-description">

                <div className="input-areas">
                    
                </div>
            </section>
        </div>
    )
}

export default Footer
