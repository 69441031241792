import React, { useState, useRef } from "react";
import { projectFirestore } from "../../firebase/config";
import emailjs, { sendForm } from "emailjs-com";
import moment from "moment";
import QRCode from "qrcode";
import useSpinner from "../Spinner/useSpinner";
import Container from "@material-ui/core/Container";
import Button from "@material-ui/core/Button";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import DirectionsRunIcon from "@material-ui/icons/DirectionsRun";
import ListItemText from "@material-ui/core/ListItemText";
import IconButton from "@material-ui/core/IconButton";
import EditIcon from "@material-ui/icons/Edit";
import "./StepForm.css";


export const Review = ({ formData, navigation }) => {
  const [spinner, showSpinner, hideSpinner] = useSpinner();
  const { go } = navigation;
  var {
    company_id,
    notesCheckText,
    washCheckText,
    bookingStatus,
    ticketNo,
    parkingSpot,
    timestamp,
    serviceType,
    enterDate,
    exitDate,
    enterTime,
    enterTime12,
    exitTime,
    exitTime12,
    arrivingFrom,
    airport,
    airportCode,
    airline,
    airlineCode,
    flightNo,
    arrivingFrom,
    bookingNotes,
    firstName,
    lastName,
    customerPhone,
    emailAddress,
    plateNo,
    carYears,
    carMakes,
    carModels,
    vehicleColor,
    bookingNo,
    bookingNoString,
    url,
    noOfDays,
    totalDays,
    dailyRate,
    charges,
    accessFee,
    totalCharge,
    extraTime,
    taxRate,
  } = formData;

  QRCode.toDataURL(bookingNoString, function (err, url) {
    console.log(url)
  })

  if(airportCode === "ord") {
    airport = "OHare Intl. Airport";
    }else if(airportCode === "mdw"){
      airport = "Midway Intl. Airport";
    }else{
      airport = "Not Selected"
    }

  if(airlineCode === "EI") {
    airline = "Aer Lingus";
    }else if(airlineCode === "AM"){
    airline = "Aeromexico";
    }else if(airlineCode === "AC"){
    airline = "Air Canada";
    }else if(airlineCode === "3E"){
    airline = "Air Choice One";
    }else if(airlineCode === "AF"){
    airline = "Air France";
    }else if(airlineCode === "AI"){
    airline = "Air India";
    }else if(airlineCode === "AS"){
    airline = "Alaska Airlines";
    }else if(airlineCode === "AZ"){
    airline = "Alitalia";
    }else if(airlineCode === "g4"){
    airline = "Allegiant Air";
    }else if(airlineCode === "AA"){
    airline = "American Airlines";
    }else if(airlineCode === "NH"){
    airline = "ANA";
    }else if(airlineCode === "OZ"){
    airline = "Asiana Airlines"
    }else if(airlineCode === "OS"){
    airline = "Austrian Airlines";
    }else if(airlineCode === "TA"){
    airline = "Avianca El Salvador";
    }else if(airlineCode === "BA"){
    airline = "British Airways";
    }else if(airlineCode === "CX"){
    airline = "Cathay Pacific";
    }else if(airlineCode === "KX"){
    airline = "Cayman Airways";
    }else if(airlineCode === "MU"){
    airline = "China Eastern Airlines"
    }else if(airlineCode === "CM"){
    airline = "Copa Airlines";
    }else if(airlineCode === "DL"){
    airline = "Delta Air Lines";
    }else if(airlineCode === "EK"){
    airline = "Emirates";
    }else if(airlineCode === "EY"){
    airline = "Etihad Airways";
    }else if(airlineCode === "BR"){
    airline = "EVA Air";
    }else if(airlineCode === "AY"){
    airline = "Finnair";
    }else if(airlineCode === "F9"){
    airline = "Frontier Airlines";
    }else if(airlineCode === "HU"){
    airline = "Hainan Airlines";
    }else if(airlineCode === "IB"){
    airline = "Iberia";
    }else if(airlineCode === "FI"){
    airline = "Icelandair";
    }else if(airlineCode === "4O"){
    airline = "Interjet";
    }else if(airlineCode === "JL"){
    airline = "Japan Airlines";
    }else if(airlineCode === "B6"){
    airline = "JetBlue Airways";
    }else if(airlineCode === "KL"){
    airline = "KLM";
    }else if(airlineCode === "KE"){
    airline = "Korean Air";
    }else if(airlineCode === "LO"){
    airline = "LOT Polish Airlines";
    }else if(airlineCode === "LH"){
    airline = "Lufthansa";
    }else if(airlineCode === "pd"){
    airline = "Porter Air";
    }else if(airlineCode === "QR"){
    airline = "Qatar Airways";
    }else if(airlineCode === "RJ"){
    airline = "Royal Jordanian";
    }else if(airlineCode === "SK"){
    airline = "Scandinavian Airlines SAS";
    }else if(airlineCode === "WN"){
    airline = "Southwest Airlines";
    }else if(airlineCode === "NK"){
    airline = "Spirit Airlines";
    }else if(airlineCode === "LX"){
    airline = "Swiss International Air Lines";
    }else if(airlineCode === "TK"){
    airline = "THY Turkish Airlines";
    }else if(airlineCode === "UA"){
    airline = "United Airlines";
    }else if(airlineCode === "VX"){
    airline = "Virgin America";
    }else if(airlineCode === "VS"){
    airline = "Virgin Atlantic";
    }else if(airlineCode === "Y4"){
    airline = "Volaris";
    }else if(airlineCode === "WS"){
    airline = "WestJet";
    }else{
    airline = "Non selected";
    }
    var airlineCode = airlineCode.toLowerCase();
  

  const collectionRef = projectFirestore.collection("bookings");
  function send() {
    collectionRef.add({
      company_id: company_id,
      notesCheckText: notesCheckText,
      washCheckText: washCheckText,
      bookingStatus: bookingStatus,
      ticketNo: ticketNo,
      parkingSpot: parkingSpot,
      timestamp: timestamp,
      serviceType: serviceType,
      bookingNo: bookingNo,
      enterDate: moment(enterDate).format("MM/DD/YYYY"),
      exitDate: moment(exitDate).format("MM/DD/YYYY"),
      enterTime: enterTime,
      enterTime12: enterTime12,
      exitTime: exitTime,
      exitTime12: exitTime12,
      plateNo: plateNo.toUpperCase(),
      carYears: carYears,
      carMakes: carMakes,
      carModels: carModels,
      vehicleColor: vehicleColor,
      airport: airport,
      airportCode: airportCode,
      airline: airline,
      airlineCode: airlineCode,
      arrivingFrom: arrivingFrom,
      bookingNotes: bookingNotes,
      flightNo: flightNo,
      firstName: firstName,
      lastName: lastName,
      customerPhone: customerPhone,
      emailAddress: emailAddress,
      noOfDays: noOfDays,
      totalDays: totalDays,
      dailyRate: dailyRate,
      charges: charges,
      accessFee: accessFee,
      totalCharge: totalCharge,
      extraTime: extraTime,
      taxRate: taxRate,
    });
  };

  

  const calculateDays = (enterDate, exitDate) => {
    const start = new Date(enterDate); //clone
    const end = new Date(exitDate); //clone
    let dayCount = 1;
    while (end > start) {
      dayCount++;
      start.setDate(start.getDate() + 1);
    }
    return dayCount;
  };
  const enterDateFormated = moment(enterDate).format("MMM Do YY");
  const exitDateFormated = moment(exitDate).format("MMM Do YY");
  var totalDays = calculateDays(enterDate, exitDate);
  var dailyRate = 22;
  const dailyRateFormated =
    "$" + dailyRate.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
  const dailyRateFixed = dailyRate.toFixed(2);
  var charges = totalDays * dailyRate;
  const chargesFixed = charges;
  const chargesFixedFormated =
    "$" +
    chargesFixed.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
  const stcPercent = 22;
  var accessFee = (chargesFixed * stcPercent) / 100;
  const accessFeeFormated = "$" + accessFee.toFixed(2);
  var totalCharge = charges + accessFee;
  const totalValetChargesFixedFormated =
    "$" + totalCharge.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
    var enterTime12 = moment(enterTime, ["HH:mm"]).format("h:mm A");
  var exitTime12 = moment(exitTime, ["HH:mm"]).format("h:mm A");
  function sendEmail(e) {
    e.preventDefault();
      emailjs
      .sendForm(
        "service_m54r5ev",
        // "template_mjw82iw",
        "template_vr7c7w9",
        e.target,
        "user_TgDZwyXKrkH1kPQD3MGJE"
      )
      emailjs
      .sendForm(
        "service_m54r5ev",
        "template_mjw82iw",
        // "template_vr7c7w9",
        e.target,
        "user_TgDZwyXKrkH1kPQD3MGJE"
      )
      .then(
        (result) => {
          console.log(result.text);
        },
        (error) => {
          console.log(error.text);
        }
      );
  };
  
  const myRef = useRef(null)

  const executeScroll = () => myRef.current.scrollIntoView(0, 0)    
  // run this function from an event handler or an effect to execute scroll

  return (
    <div className="hero-action-container" ref={myRef}>
      <h2>Review</h2>
      <hr />
      <form onSubmit={sendEmail} id="service_m54r5ev">
      <input
          type="text"
          defaultValue={timestamp}
          name="timestamp"
          placeholder="timestamp"
          hidden
        ></input>
        <input
          type="text"
          defaultValue={bookingNo}
          name="bookingNo"
          id="bookingNo"
          placeholder="bookingNo"
          hidden
        ></input>
          <input
          type="text"
          defaultValue={serviceType}
          name="serviceType"
          placeholder="serviceType"
          hidden
        ></input>
        <input
          type="text"
          defaultValue={firstName}
          name="firstName"
          placeholder="firstName"
          hidden
        ></input>
        <input
          type="text"
          defaultValue={lastName}
          name="lastName"
          placeholder="lastName"
          hidden
        ></input>
        <input type="text" 
        defaultValue={emailAddress} 
        name="emailAddress"
        placeholder="emailAddress"
        hidden
        ></input>
        <input type="text" 
        defaultValue={customerPhone} 
        name="customerPhone"
        placeholder="customerPhone"
        hidden
        ></input>
        <input
          type="text"
          placeholder="airportCode"
          defaultValue={airportCode}
          name="airportCode"
          id="airportCode"
          placeholder="airportCode"
          hidden
        ></input>
        <input
          type="text"
          placeholder="airport"
          defaultValue={airport}
          name="airport"
          id="airport"
          placeholder="airport"
          hidden
        ></input>
        <input
          type="text"
          placeholder="airline"
          defaultValue={airline}
          name="airline"
          id="airline"
          placeholder="airline"
          hidden
        ></input>
        <input
          type="text"
          placeholder="airlineCode"
          defaultValue={airlineCode}
          name="airlineCode"
          id="airlineCode"
          placeholder="airlineCode"
          hidden
        ></input>
        <input
          type="text"
          placeholder="flightNo"
          defaultValue={flightNo}
          name="flightNo"
          id="flightNo"
          placeholder="flightNo"
          hidden
        ></input>
        <input
          type="text"
          placeholder="arrivingFrom"
          defaultValue={arrivingFrom}
          name="arrivingFrom"
          id="arrivingFrom"
          placeholder="arrivingFrom"
          hidden
        ></input>
        <input
          type="text"
          placeholder="bookingNotes"
          defaultValue={bookingNotes}
          name="bookingNotes"
          id="bookingNotes"
          placeholder="bookingNotes"
          hidden
        ></input>
        <input
          type="text"
          placeholder="totalDays"
          defaultValue={totalDays}
          name="totalDays"
          id="totalDays"
          placeholder="totalDays"
          hidden
        ></input>
        <input
          type="text"
          placeholder="charges"
          defaultValue={charges}
          name="charges"
          id="charges"
          placeholder="charges"
          hidden
        ></input>
        <input
          type="text"
          placeholder="accessFee"
          defaultValue={accessFee}
          name="accessFee"
          id="accessFee"
          placeholder="accessFee"
          hidden
        ></input>
        <input
          type="text"
          placeholder="totalCharge"
          defaultValue={totalCharge}
          name="totalCharge"
          id="totalCharge"
          placeholder="totalCharge"
          hidden
        ></input>
        <input
          type="text"
          placeholder="carYears"
          defaultValue={carYears}
          name="carYears"
          id="carYears"
          placeholder="carYears"
          hidden
        ></input>
        <input
          type="text"
          placeholder="carMakes"
          defaultValue={carMakes}
          name="carMakes"
          id="carMakes"
          placeholder="carMakes"
          hidden
        ></input>
        <input
          type="text"
          placeholder="carModels"
          defaultValue={carModels}
          name="carModels"
          id="carModels"
          placeholder="carModels"
          hidden
        ></input>
        

        <input
          type="text"
          placeholder="vehicleColor"
          defaultValue={vehicleColor}
          name="vehicleColor"
          id="vehicleColor"
          placeholder="vehicleColor"
          hidden
        ></input>
        <input
          type="text"
          defaultValue={moment(enterDate).format("MM/DD/YYYY")}
          name="enterDate"
          id="enterDate"
          placeholder="enterDate"
          hidden
        ></input>
        <input
          type="text"
          defaultValue={moment(exitDate).format("MM/DD/YYYY")}
          name="exitDate"
          id="exitDate"
          placeholder="exitDate"
          hidden
        ></input>
        <input
          type="text"
          defaultValue={enterTime}
          name="enterTime"
          id="enterTime"
          placeholder="enterTime"
          hidden
        ></input>
        <input
          type="text"
          defaultValue={exitTime}
          name="exitTime"
          id="exitTime"
          placeholder="exitTime"
          hidden
        ></input>
                <input
          type="text"
          defaultValue={enterTime12}
          name="enterTime12"
          id="enterTime12"
          placeholder="enterTime12"
          hidden
        ></input>
        <input
          type="text"
          defaultValue={exitTime12}
          name="exitTime12"
          id="exitTime12"
          placeholder="exitTime12"
          hidden
        ></input>
        <input
          type="text"
          defaultValue={notesCheckText}
          name="notesCheckText"
          id="notesCheckText"
          placeholder="notesCheckText"
          hidden
        ></input>
        <input
          type="text"
          defaultValue={washCheckText}
          name="washCheckText"
          id="washCheckText"
          placeholder="washCheckText"
          hidden
        ></input>
        <input
          type="text"
          defaultValue={bookingStatus}
          name="bookingStatus"
          id="bookingStatus"
          placeholder="bookingStatus"
          hidden
        ></input>
        <input
          type="text"
          defaultValue={ticketNo}
          name="ticketNo"
          id="ticketNo"
          placeholder="ticketNo"
          hidden
        ></input>
        <input
          type="text"
          defaultValue={parkingSpot}
          name="parkingSpot"
          id="parkingSpot"
          placeholder="parkingSpot"
          hidden
        ></input>
        
        <input
          type="text"
          defaultValue={url}
          name="url"
          id="url"
          placeholder="url"
          hidden
        ></input>

        <div className="md-stepper-horizontal orange">
          <div className="md-step active">
            <div className="md-step-circle">
              <span>
                <i className="fas fa-check-circle"></i>
              </span>
            </div>
            <div className="md-step-title">Dates</div>
            <div className="md-step-optional-dark">Times</div>
            <div className="md-step-bar-left"></div>
            <div className="md-step-bar-right"></div>
          </div>
          <div className="md-step active">
            <div className="md-step-circle">
              <span>
                <i className="fas fa-check-circle"></i>
              </span>
            </div>
            <div className="md-step-title">Vehicle</div>
            <div className="md-step-optional-dark">Return</div>
            <div className="md-step-bar-left"></div>
            <div className="md-step-bar-right"></div>
          </div>
          <div className="md-step active">
            <div className="md-step-circle">
              <span>
                <i className="fas fa-check-circle"></i>
              </span>
            </div>
            <div className="md-step-title"> Contact</div>
            <div className="md-step-optional">
              <i className="fas fa-caret-right"></i> Review
            </div>
            <div className="md-step-bar-left"></div>
            <div className="md-step-bar-right"></div>
          </div>
        </div>
        {spinner}
        <Accordion>
          <AccordionSummary aria-controls="panel1a-content" id="panel1a-header">
            <div className="summary-container">
              <div className="left-one">Valet</div>
              <div className="right-one">Charges</div>
              <div className="right-two">Days</div>
              <div className="left-one-text">
                <DirectionsRunIcon />
              </div>
              <div className="right-two-text">
                {totalValetChargesFixedFormated}
              </div>
              <div className="right-one-text">{totalDays}</div>
              <div style={{verticalAlign: "bottom", fontWeight: "200"}}>Show Details {<ExpandMoreIcon style={{verticalAlign: "bottom", marginBottom: "-3px"}}/>}</div>
            </div>
          </AccordionSummary>
          <AccordionDetails>
            <div className="quote-content">
              <div className="quote-summary">
                <span className="left">
                  <span className="lite-text">
                    Start: <br />
                  </span>{" "}
                  {enterDateFormated} <br /> {enterTime12}{" "}
                </span>
                <span className="right">
                  <span className="lite-text">
                    End: <br />
                  </span>{" "}
                  {exitDateFormated} <br /> {exitTime12}{" "}
                </span>
                <IconButton
                  color="primary"
                  component="span"
                  onClick={() => go("dates")}
                >
                  <EditIcon />{" "}
                  
                </IconButton>
              </div>
              <div className="quote-calculations">
                <div className="pricing-left">
                  Subtotal ({totalDays} X {dailyRateFormated} Day)
                </div>

                <div className="pricing-right" name="charges">
                  {chargesFixedFormated}
                </div>

                <div className="pricing-left">Airport Fee</div>
                <div className="pricing-right" name="accessFee">
                  {accessFeeFormated}
                </div>
                <div className="pricing-left">Total Charges</div>
                <div className="pricing-right" name="totalCharge">
                  {totalValetChargesFixedFormated}
                </div>

                <div className="pricing-message-return">Due Now</div>
                <div className="pricing-total" name="totalCharge">
                  $0.0
                </div>
                <div className="pricing-message-return total">
                  Due on Return
                </div>
                <div className="pricing-total-return" name="totalCharge">
                  {totalValetChargesFixedFormated}
                </div>
              </div>
            </div>
          </AccordionDetails>
        </Accordion>
        <br />
        <Button
          color="primary"
          type="submit"
          variant="contained"
          style={{height: "55px", marginRight: "1rem", marginBottom: "1rem", fontSize: "18px" }}
          fullWidth
          onClick={() => {
            showSpinner();
            setTimeout(() => hideSpinner(), 5005);
            setTimeout(() => go("submit"), 5005);
            send();
            executeScroll();
          }}
        >
          Confirm Booking
        </Button>
        {/* {spinner} */}
      </form>
    </div>
  );
};

export const RenderAccordion = ({ summary, details, go }) => (
  <Accordion>
    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
      {summary}
    </AccordionSummary>
    <AccordionDetails>
      <div>
        {details.map((data, index) => {
          const objKey = Object.keys(data)[1];
          const objValue = data[Object.keys(data)[1]];
          
          return (
            <ListItemText key={index}>{`${objKey}: ${objValue}`}</ListItemText>
          );
        })}

        <IconButton
          color="primary"
          component="span"
          onClick={() => {
            go(`${summary.toLowerCase()}`);
            // executeScroll();
          }}
          // onClick={() => go(`${summary.toLowerCase()}`)}
        >
          <EditIcon />
        </IconButton>
      </div>
    </AccordionDetails>
  </Accordion>
);