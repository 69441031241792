import React from 'react';
import '../../App.css';
import HeroSection from '../HeroSection';
import Cards from "../Cards";
import Footer from "../Footer";


function Home () {
    // const [showSections, setShowSections] = React.useState(true)
    // const onClick = () => setShowSections(false)
    return (
        <>
            
            <HeroSection />
            {/* { showSections ? <Cards /> : null } */}
            <Cards />
            <Footer />
        </>
    )
}

export default Home;