import React from "react";
import FormHelperText from "@material-ui/core/FormHelperText";
import { makeStyles } from "@material-ui/core/styles";
import Accordion from "@material-ui/core/Accordion";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import FlightLandIcon from "@material-ui/icons/FlightLand";
import PaymentIcon from "@material-ui/icons/Payment";
import WorkOutlineIcon from "@material-ui/icons/WorkOutline";
import SmsOutlinedIcon from "@material-ui/icons/SmsOutlined";
import ConfirmationNumberOutlinedIcon from "@material-ui/icons/ConfirmationNumberOutlined";
import DirectionsCarOutlinedIcon from "@material-ui/icons/DirectionsCarOutlined";
import SmartphoneOutlinedIcon from "@material-ui/icons/SmartphoneOutlined";
import AirportShuttleIcon from "@material-ui/icons/AirportShuttle";
import LocalParkingIcon from "@material-ui/icons/LocalParking";
import FlightTakeoffIcon from "@material-ui/icons/FlightTakeoff";
import SecurityIcon from "@material-ui/icons/Security";
import EmojiTransportationIcon from "@material-ui/icons/EmojiTransportation";
import PermDeviceInformationIcon from "@material-ui/icons/PermDeviceInformation";
import "./HowItWorks.css";


import styled from "styled-components";

const Container = styled.div`
  width: 100%;
  padding: 30px 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-family: "Quicksand", sans-serif;
`;
const Header = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;
const Title = styled.h3`
  font-family: "Quicksand", sans-serif;
  font-size: 30px;
  margin: 15px 0;
`;
const SubTitle = styled.p`
  font-weight: 400;
  font-size: 16px;
  margin-top: 0;
  margin-bottom: 15px;
  font-family: "Quicksand", sans-serif;
`;
const StepContainer = styled.div`
  width: 100%;
  margin-top: 10px;
  display: flex;
  @media (max-width: 450px) {
    flex-direction: column;
    align-items: center;
  }
  .arrow-first {
    &:before {
      border-color: #d08d0e !important;
      content: " ";
      width: calc(100% + 45px);
      height: calc(50% + 130px);
      top: -52%;
      border: 2px dotted transparent;
      position: absolute;
      border-radius: 50%;
      border-top-width: 0;
      border-left-width: 0;
      border-right-width: 0;
      left: calc(-50% - 23px);
      z-index: -1;
      @media (max-width: 450px) {
        border-color: transparent !important;
      }
    }
  }
  .arrow-second {
    &:before {
      border-color: #d08d0e !important;
      border-top-width: 2px;
      border-bottom-width: 0 !important;
      top: 4%;
      content: " ";
      width: calc(100% + 45px);
      height: calc(50% + 130px);
      border: 2px dotted transparent;
      position: absolute;
      border-radius: 50%;
      border-left-width: 0;
      border-right-width: 0;
      left: calc(-50% - 23px);
      z-index: -1;
      @media (max-width: 450px) {
        border-color: transparent !important;
      }
    }
  }
`;
const StepBox = styled.div`
  position: relative;
  padding: 20px;
  text-align: center;
  margin-bottom: 50px;
  width: 33.32%;
  @media (max-width: 450px) {
    width: 100%;
  }
`;
const Icon = styled.div`
  margin-bottom: 35px;
  background: #d08d0e;
  color: #ffffff;
  width: 100px;
  height: 100px;
  line-height: 100px;
  font-size: 30px;
  box-shadow: 0 0 0 10px #fff;
  display: inline-block;
  border-radius: 50%;
  text-align: center;
  flex-shrink: 0;
  .user {
  }
`;
const StepContent = styled.div``;
const StepContentHeader = styled.h5`
  margin: 0;
  margin-bottom: 15px;
  font-size: 18px;
`;
const StepContentText = styled.p`
  margin: 0;
  color: #999;
  line-height: 23px;
  opacity: 0.7;
`;

export default function HowItWorks() {
  return (
    <Container>
      <h1
      style={{color: "#d08d0e"}}>How It Works</h1>
      <Header>
        <Title>On Your Parking Day</Title>
        <SubTitle>Here is a brief summary of the check-in procedure.</SubTitle>
      </Header>
      <StepContainer>
        <StepBox>
          <Icon>
            <i className="user" />
            <EmojiTransportationIcon 
            style={{fontSize: "65px", marginTop: "20px"}}
            className="howItWorksCheckin" />
          </Icon>
          <StepContent>
            <StepContentHeader>Check-In</StepContentHeader>
            <StepContentText>
              Arrive at our facility at your scheduled parking time, our staff will get you checked in within minutes.
            </StepContentText>
          </StepContent>
        </StepBox>
        
        <StepBox className="arrow-first">
          <Icon>
            <AirportShuttleIcon 
            style={{fontSize: "65px", marginTop: "20px"}}
            className="howItWorksShuttle" />
          </Icon>
          <StepContent>
            <StepContentHeader>Ride to Airport</StepContentHeader>
            <StepContentText>
            A luxury shuttle will be waiting for you to whisk you off to the airport. We are less than 5 minutes from the terminal.
            </StepContentText>
          </StepContent>
        </StepBox>

        <StepBox className="arrow-second">
        <Icon>
            <SecurityIcon 
            style={{fontSize: "65px", marginTop: "20px"}}
            className="howItWorksParked" />
          </Icon>
          <StepContent>
            <StepContentHeader>Vehicle Securly Parked</StepContentHeader>
            <StepContentText>
            Your vehicle will be safely and securely parked at our facility. 
            </StepContentText>
          </StepContent>
        </StepBox>
      </StepContainer>

      <Header>
        <Title>When You Return</Title>
        <SubTitle>When you return from your journey, please follow these instructions to retrieve your vehicle.</SubTitle>

      </Header>

      <StepContainer>
        <StepBox>
          <Icon>
          <SmsOutlinedIcon 
          style={{fontSize: "65px", marginTop: "20px"}}
          className="howItWorksSms" />
            <i className="user" />
          </Icon>
          <StepContent>
            <StepContentHeader>Text for your Vehicle</StepContentHeader>
            <StepContentText>
            When your flight lands, proceed to baggage claim and retrieve any checked luggage then send us a text message with your claim ticket number. Your vehicle will arrive within 10 minutes depending on airport traffic conditions.
            </StepContentText>
          </StepContent>
        </StepBox>
    
        <StepBox className="arrow-first">
          <Icon>
            <PaymentIcon 
            style={{fontSize: "65px", marginTop: "20px"}}
            className="howItWorksPayment" />
          </Icon>
          <StepContent>
            <StepContentHeader>Pay With Your SmartPhone.</StepContentHeader>
            <StepContentText>
              While waiting for your vehicle, now is the perfect time to make your parking payment. We will send you a link that shows your detailed charges and you can pay with all major credit cards.
            </StepContentText>
          </StepContent>
        </StepBox>
        <StepBox className="arrow-second">
          <Icon>
            <DirectionsCarOutlinedIcon 
            style={{fontSize: "65px", marginTop: "20px"}}
            className="howItWorksPayment" />
          </Icon>
          <StepContent>
            <StepContentHeader>Drive Your Vehicle Home From The Airport</StepContentHeader>
            <StepContentText>
              Your vehicle will pull and the attendent will hand you your keys. Attendent will make his own way back. You are all set. Drive your vehicle home from the airport.
            </StepContentText>
          </StepContent>
        </StepBox>
      </StepContainer>
    </Container>
  );
}
