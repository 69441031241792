import React from 'react';
import '../../components/pages/Greeter.css'

import '../../App.css';

export default function Greeter() {
    
    return (
        <div className='greeter-container'>
            <img src="/images/valet-info.jpg" />
            <h1 className='greeter'>Valet Greeters</h1>
        </div>

        
        
    )
}