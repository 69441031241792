import React from "react";
import { useForm, useStep, Controller } from "react-hooks-helper";
import { Action } from "./stepForm/Action";
import { Dates } from "./stepForm/Dates";
import { Return } from "./stepForm/Return";
import { Contact } from "./stepForm/Contact";
import { Vehicle } from "./stepForm/Vehicle";
import { Review } from "./stepForm/Review";
import { Submit } from "./stepForm/Submit";

const bookingNumber = Math.floor(Math.random() * 9999 + 9999);
const bookingNoString = bookingNumber.toString();

const defaultData = {
  company_id: "ZLGsGn0TeqputrEmxbFY",
  notesCheckText: "--",
  washCheckText: "--",
  bookingStatus: "Booked",
  ticketNo: "--",
  parkingSpot: "--",
  timestamp: new Date(),
  bookingNoURL: "",
  bookingNoString: bookingNoString,
  bookingNo: bookingNumber,
  serviceType: "SpeedPark",
  enterDate: "",
  exitDate: "",
  enterTime: "",
  enterTime12: "",
  exitTime: "",
  exitTime12: "",
  plateNo: "",
  carYears: "",
  carMakes: "",
  carModels: "",
  vehicleColor: "",
  airport: "",
  airportCode: "ord",
  airline: "",
  airlineCode: "",
  flightNo: "",
  arrivingFrom: "",
  bookingNotes: "",
  firstName: "",
  lastName: "",
  customerPhone: "",
  emailAddress: "",
  meetingPoint: "Curbside",
  noOfDays: 0,
  extraTime: 0,
  taxRate: 0.2,
  totalDays: null,
  dailyRate: "",
  charges: "",
  accessFee: "",
  totalCharge: "",
};

const steps = [
  { id: "action" },
  { id: "dates" },
  { id: "vehicle" },
  { id: "return" },
  { id: "contact" },
  { id: "review" },
  { id: "submit" },
];

export const MultiStepForm = () => {
  const [formData, setForm] = useForm(defaultData);
  const { step, navigation } = useStep({
    steps,
    initialStep: 0,
    
  });
  

  const props = { formData, setForm, navigation };

  switch (step.id) {
    case "action":
      return <Action {...props} />;
    case "dates":
      return <Dates {...props} />;
    case "return":
      return <Return {...props} />;
    case "contact":
      return <Contact {...props} />;
    case "vehicle":
      return <Vehicle {...props} />;
    case "review":
      return <Review {...props} />;
    case "submit":
      return <Submit {...props} />;
  }

  return (
    <div>
      
    </div>
  );
};

export default MultiStepForm;
