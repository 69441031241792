import React from "react";
import { MultiStepForm } from "../MultiStepForm";
import { Button } from "../Button";
import "../../components/pages/Valet.css";
import "../../App.css";
import "../HeroSection.css";

export default function Reserve() {
  return (
    <div className='hero-container'>
            <img 
            src="/images/valet_parking_chicago.jpg" 
            // autoPlay 
            // loop 
            // muted 
            style={{
                position: "absolute",
                width: "100%",
                left: "50%",
                top: "50%",
                height: "120%",
                objectFit: "cover",
                transform: "translate(-50%, -50%)",
                zIndex: "-1"
                
            }}/>
      {/* <h1>Fast, Curbside Valet.</h1> */}
      {/* <p>Reserve with 3 easy steps!</p> */}
      <div className="hero-btns">
        {/* <Button
          className="btns"
          buttonStyle="btn--outline"
          buttonSize="btn--large"
        >
          RESERVE
        </Button> */}
        <Button
          className="btns"
          buttonStyle="btn--primary"
          buttonSize="btn--large"
        >
          HOW IT WORKS <i className="far fa play circle" />
        </Button>
      </div>
      <MultiStepForm />
    </div>
  );
}