import React from 'react';
import './Button.css';
import { Link } from 'react-router-dom';

const STYLES = ['btn--primary', 'btn--outline'];

const SIZES = ['btn--mediam', 'btn--large'];

export const Button = ({
    children, 
    type, 
    onClick, 
    buttonStyle, 
    buttonSize
}) => {
    const checkButtonStyle = STYLES.includes(buttonStyle)
     ? buttonStyle 
     : STYLES[0];

     const checkButtonSizes = SIZES.includes(buttonSize)
     ? buttonSize: SIZES[0];

     return (
         <Link to='/how-it-works' className='btn-mobile'>
             <button
             className={`btn ${checkButtonStyle} ${checkButtonSizes}`}
             onClick={onClick}
             type={type}
             >
                 {children}
             </button>
         </Link>
     )

};
