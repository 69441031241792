import React from 'react';
import '../App.css';
import { Button } from './Button';
import MultiStepForm from './MultiStepForm';
import './HeroSection.css';

function HeroSection() {
    return (
        <div className='hero-container'>
            <img 
            src="/images/airport_platinum.jpg"
            // autoPlay 
            // loop 
            // muted 
            style={{
                position: "absolute",
                width: "100%",
                left: "50%",
                top: "50%",
                height: "120%",
                objectFit: "cover",
                transform: "translate(-50%, -50%)",
                zIndex: "-1"
                
            }}/>
            {/* <h1>O'Hare Airport</h1> */}
            {/* <p style={{color: "#e29902", fontSize: "40px", fontWeight: "900"}}>Valet Parking</p> */}
            <div className='hero-btns'>
                {/* <Button
                    className='btns'
                    buttonStyle='btn--outline'
                    buttonSize='btn--large'
                    >
                    RESERVE
                </Button> */}
                <Button
                    className='btns'
                    buttonStyle='btn--primary'
                    buttonSize='btn--large'
                    >
                    HOW IT WORKS <i className='far fa play circle' />
                </Button>
            </div>
            {/* <HeroAction /> */}
            <MultiStepForm />
        </div>

    )
}

export default HeroSection
