import React, { useState, useRef } from "react";
// import Select from 'react-select';
import moment from "moment";
import useSpinner from "../Spinner/useSpinner";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Container from "@material-ui/core/Container";
import Button from "@material-ui/core/Button";
import Select from "@material-ui/core/Select";
import "./StepForm.css";
import "./Stepper.css";

import MenuItem from "@material-ui/core/MenuItem";
import FormHelperText from "@material-ui/core/FormHelperText";

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    flexWrap: "wrap",
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 200,
  },
}));



export const Return = ({ formData, setForm, navigation }) => {
  const [spinner, showSpinner, hideSpinner] = useSpinner();


  var {
    enterDate,
    exitDate,
    enterTime,
    exitTime,
    airport,
    airportCode,
    airline,
    airlineCode,
    flightNo,
    arrivingFrom,
    bookingNo,
  } = formData;
  
  const enterDateFormated = moment(enterDate).format("MMM Do YYYY");
  const exitDateFormated = moment(exitDate).format("MMM Do YYYY");

  const [bookingNumber, setBookingNo] = useState(bookingNo);

  const [airlineVal, setAirlineVal] = useState("");
  const [airlineText, setAirlineText] = useState("");
  
  const handleAirlineCode = (e) => {
    setAirlineVal(e.target.value);
    setForm(e)
}

  if(airportCode === "ord") {
  airport = "OHare Intl. Airport";
  }else if(airportCode === "mdw"){
    airport = "Midway Intl. Airport";
  }else{
    airport = "Not Selected"
  }

  
  if(airlineCode === "EI") {
  airline = "Aer Lingus";
  }else if(airlineCode === "AM"){
  airline = "Aeromexico";
  }else if(airlineCode === "AC"){
  airline = "Air Canada";
  }else if(airlineCode === "3E"){
  airline = "Air Choice One";
  }else if(airlineCode === "AF"){
  airline = "Air France";
  }else if(airlineCode === "AI"){
  airline = "Air India";
  }else if(airlineCode === "AS"){
  airline = "Alaska Airlines";
  }else if(airlineCode === "AZ"){
  airline = "Alitalia";
  }else if(airlineCode === "g4"){
  airline = "Allegiant Air";
  }else if(airlineCode === "AA"){
  airline = "American Airlines";
  }else if(airlineCode === "NH"){
  airline = "ANA";
  }else if(airlineCode === "OZ"){
  airline = "Asiana Airlines"
  }else if(airlineCode === "OS"){
  airline = "Austrian Airlines";
  }else if(airlineCode === "TA"){
  airline = "Avianca El Salvador";
  }else if(airlineCode === "BA"){
  airline = "British Airways";
  }else if(airlineCode === "CX"){
  airline = "Cathay Pacific";
  }else if(airlineCode === "KX"){
  airline = "Cayman Airways";
  }else if(airlineCode === "MU"){
  airline = "China Eastern Airlines"
  }else if(airlineCode === "CM"){
  airline = "Copa Airlines";
  }else if(airlineCode === "DL"){
  airline = "Delta Air Lines";
  }else if(airlineCode === "EK"){
  airline = "Emirates";
  }else if(airlineCode === "EY"){
  airline = "Etihad Airways";
  }else if(airlineCode === "BR"){
  airline = "EVA Air";
  }else if(airlineCode === "AY"){
  airline = "Finnair";
  }else if(airlineCode === "F9"){
  airline = "Frontier Airlines";
  }else if(airlineCode === "HU"){
  airline = "Hainan Airlines";
  }else if(airlineCode === "IB"){
  airline = "Iberia";
  }else if(airlineCode === "FI"){
  airline = "Icelandair";
  }else if(airlineCode === "4O"){
  airline = "Interjet";
  }else if(airlineCode === "JL"){
  airline = "Japan Airlines";
  }else if(airlineCode === "B6"){
  airline = "JetBlue Airways";
  }else if(airlineCode === "KL"){
  airline = "KLM";
  }else if(airlineCode === "KE"){
  airline = "Korean Air";
  }else if(airlineCode === "LO"){
  airline = "LOT Polish Airlines";
  }else if(airlineCode === "LH"){
  airline = "Lufthansa";
  }else if(airlineCode === "pd"){
  airline = "Porter Air";
  }else if(airlineCode === "QR"){
  airline = "Qatar Airways";
  }else if(airlineCode === "RJ"){
  airline = "Royal Jordanian";
  }else if(airlineCode === "SK"){
  airline = "Scandinavian Airlines SAS";
  }else if(airlineCode === "WN"){
  airline = "Southwest Airlines";
  }else if(airlineCode === "NK"){
  airline = "Spirit Airlines";
  }else if(airlineCode === "LX"){
  airline = "Swiss International Air Lines";
  }else if(airlineCode === "TK"){
  airline = "THY Turkish Airlines";
  }else if(airlineCode === "UA"){
  airline = "United Airlines";
  }else if(airlineCode === "VX"){
  airline = "Virgin America";
  }else if(airlineCode === "VS"){
  airline = "Virgin Atlantic";
  }else if(airlineCode === "Y4"){
  airline = "Volaris";
  }else if(airlineCode === "WS"){
  airline = "WestJet";
  }else{
  airline = "Non selected";
  }

  const myRef = useRef(null)

  const executeScroll = () => myRef.current.scrollIntoView(0, 0)    
  // run this function from an event handler or an effect to execute scroll
  

  return (
    <div className="hero-action-container" ref={myRef}>
      <h2>Return Flight</h2>
      <hr />
      <div className="md-stepper-horizontal orange">
        <div className="md-step active">
          <div className="md-step-circle">
            <span>
              <i className="fas fa-check-circle"></i>
            </span>
          </div>
          <div className="md-step-title">Dates</div>
          <div className="md-step-optional-dark">Times</div>
          <div className="md-step-bar-left"></div>
          <div className="md-step-bar-right"></div>
        </div>
        <div className="md-step active">
          <div className="md-step-circle">
            <span>2</span>
          </div>
          <div className="md-step-title">Vehicle</div>
          <div className="md-step-optional">
            <i className="fas fa-caret-right"></i> Return
          </div>
          <div className="md-step-bar-left"></div>
          <div className="md-step-bar-right"></div>
        </div>
        <div className="md-step">
          <div className="md-step-circle">
            <span>3</span>
          </div>
          <div className="md-step-title">Contact</div>
          <div className="md-step-title">Review</div>
          <div className="md-step-bar-left"></div>
          <div className="md-step-bar-right"></div>
        </div>
      </div>
      {spinner}
      <div className="main-content-group">
          <FormHelperText>Airport</FormHelperText>
          <Select
            name="airportCode"
            labelId="airportCode"
            id="airportCode"
            value={airportCode}
            displayEmpty
            onChange={setForm}
            variant="outlined"
            fullWidth
          >
            <MenuItem value="" disabled>
              Select Airline...
            </MenuItem>
            <MenuItem value="ord" selected>
              OHare Intl. Airport
            </MenuItem>
            <MenuItem value="mdw">Midway Intl. Airport</MenuItem>
          </Select>
          <FormHelperText>Airline</FormHelperText>
          <Select
            name="airlineCode"
            labelId="airlineCode"
            id="airlineCode"
            value={airlineCode}
            displayEmpty
            onChange={handleAirlineCode}
            variant="outlined"
            fullWidth
          >
            <MenuItem value="" disabled>
              Select Airline
            </MenuItem>
            <MenuItem value="AA" selected>
              American Airlines
            </MenuItem>
            <MenuItem value="EI">Aer Lingus</MenuItem>
            <MenuItem value="AM">Aeromexico</MenuItem>
            <MenuItem value="AC">Air Canada</MenuItem>
            <MenuItem value="3E">Air Choice One</MenuItem>
            <MenuItem value="AF">Air France</MenuItem>
            <MenuItem value="AI">Air India</MenuItem>
            <MenuItem value="AS">Alaska Airlines</MenuItem>
            <MenuItem value="AZ">Alitalia</MenuItem>
            <MenuItem value="AA">American Airlines</MenuItem>
            <MenuItem value="NH">ANA</MenuItem>
            <MenuItem value="OZ">Asiana Airlines</MenuItem>
            <MenuItem value="OS">Austrian Airlines</MenuItem>
            <MenuItem value="TA">Avianca El Salvador</MenuItem>
            <MenuItem value="BA">British Airways</MenuItem>
            <MenuItem value="CX">Cathay Pacific</MenuItem>
            <MenuItem value="KX">Cayman Airways</MenuItem>
            <MenuItem value="MU">China Eastern Airlines</MenuItem>
            <MenuItem value="CM">Copa Airlines</MenuItem>
            <MenuItem value="DL">Delta Air Lines</MenuItem>
            <MenuItem value="EK">Emirates</MenuItem>
            <MenuItem value="EY">Etihad Airways</MenuItem>
            <MenuItem value="BR">EVA Air</MenuItem>
            <MenuItem value="AY">Finnair</MenuItem>
            <MenuItem value="F9">Frontier Airlines</MenuItem>
            <MenuItem value="HU">Hainan Airlines</MenuItem>
            <MenuItem value="IB">Iberia</MenuItem>
            <MenuItem value="FI">Icelandair</MenuItem>
            <MenuItem value="4O">Interjet</MenuItem>
            <MenuItem value="JL">Japan Airlines</MenuItem>
            <MenuItem value="B6">JetBlue Airways</MenuItem>
            <MenuItem value="KL">KLM</MenuItem>
            <MenuItem value="KE">Korean Air</MenuItem>
            <MenuItem value="LO">LOT Polish Airlines</MenuItem>
            <MenuItem value="LH">Lufthansa</MenuItem>
            <MenuItem value="QR">Qatar Airways</MenuItem>
            <MenuItem value="RJ">Royal Jordanian</MenuItem>
            <MenuItem value="SK">Scandinavian Airlines SAS</MenuItem>
            <MenuItem value="WN">Southwest Airlines</MenuItem>
            <MenuItem value="NK">Spirit Airlines</MenuItem>
            <MenuItem value="LX">Swiss International Air Lines</MenuItem>
            <MenuItem value="TK">THY Turkish Airlines</MenuItem>
            <MenuItem value="UA">United Airlines</MenuItem>
            <MenuItem value="VX">Virgin America</MenuItem>
            <MenuItem value="VS">Virgin Atlantic</MenuItem>
            <MenuItem value="Y4">Volaris</MenuItem>
            <MenuItem value="WS">WestJet</MenuItem>
          </Select>

          <TextField
            label="Flight#"
            name="flightNo"
            value={flightNo}
            onChange={setForm}
            margin="normal"
            variant="outlined"
            autoComplete="off"
            variant="outlined"
            fullWidth
          />
          <input type="text" defaultValue="arrivingFrom" hidden></input>
          <input type="text" name="airline" id="airline" onChange={setForm} value={airline} hidden/>

         
      </div>
      <div className="btns-container">
        <Button
          className="left-btn"
          color="default"
          variant="outlined"
          
          onClick={() => {
            showSpinner();
            setTimeout(() => hideSpinner(), 500);
            setTimeout(() => navigation.previous(), 500);
            executeScroll();
          }}
        >
          Back
        </Button>
        <Button
          className="center-btn"
          color="primary"
          variant="contained"
          // style={{ marginBottom: "1rem" }}
          onClick={() => {
          
            showSpinner();
            setTimeout(() => hideSpinner(), 500);
            setTimeout(() => navigation.next(), 500);
            executeScroll();
          }}
        >
          Next
        </Button>
        <Button
          className="right-btn"
          color="primary"
          variant="outlined"
          style={{marginTop: "20px"}}
          onClick={() => {
        
            showSpinner();
            setTimeout(() => hideSpinner(), 500);
            setTimeout(() => navigation.next(), 500);
            executeScroll();
          }}
        >
          Skip
        </Button>
        {/* {spinner} */}
      </div>
    </div>
  );
};
