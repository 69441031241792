import React, { useState, useRef } from "react";
import useSpinner from "../Spinner/useSpinner";
import validator from 'validator';
import Container from "@material-ui/core/Container";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import FormHelperText from "@material-ui/core/FormHelperText";
import MaskedInput from "react-text-mask";
import ErrorOutlineOutlinedIcon from "@material-ui/icons/ErrorOutlineOutlined";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";

export const Contact = ({ formData, setForm, navigation }) => {
  const [spinner, showSpinner, hideSpinner] = useSpinner();
  const { firstName, 
          lastName,
          customerPhone, 
          emailAddress
        } = formData;

  const [open, setOpen] = React.useState(false);
  const handleClickOpen = () => {
    setOpen(true);
  };
  const validateContactDetails = () => {
    var nextBtn = document.getElementById("nextBtn");
    if (firstName.length < 2 || customerPhone.length !== 14 || emailAddress.length < 4 ) {
      
      // setEmailError('Enter valid Email!')
    } else {
      // setEmailError('Valid Email :)')
      const handleClickOpen = () => {
        setOpen(false);
        showSpinner();

        setTimeout(() => navigation.next(), 500);
      };

      handleClickOpen();
    }
  };

  const handleClose = () => {
    setOpen(false);
  };
  // -------End validate for dates
  const [emailError, setEmailError] = useState('')
  const validateEmail = (e) => {
    var email = e.target.value
  
    if (validator.isEmail(email)) {
      setEmailError('Valid Email :)')
    } else {
      setEmailError('Enter valid Email!')
    }
  }

  const myRef = useRef(null)

  const executeScroll = () => myRef.current.scrollIntoView(0, 0)    
  // run this function from an event handler or an effect to execute scroll
  return (
    <div className="hero-action-container" ref={myRef}>
      <h2>Contact</h2>
      <hr />
      <div className="md-stepper-horizontal orange">
        <div className="md-step active">
          <div className="md-step-circle">
            <span>
              <i className="fas fa-check-circle"></i>
            </span>
          </div>
          <div className="md-step-title">Dates</div>
          <div className="md-step-optional-dark">Times</div>
          <div className="md-step-bar-left"></div>
          <div className="md-step-bar-right"></div>
        </div>
        <div className="md-step active">
          <div className="md-step-circle">
            <span>
              <i className="fas fa-check-circle"></i>
            </span>
          </div>
          <div className="md-step-title">Vehicle</div>
          <div className="md-step-optional-dark">Return</div>
          <div className="md-step-bar-left"></div>
          <div className="md-step-bar-right"></div>
        </div>
        <div className="md-step active">
          <div className="md-step-circle">
            <span>3</span>
          </div>
          <div className="md-step-optional">
            <i className="fas fa-caret-right"></i> Contact
          </div>
          <div className="md-step-title">Review</div>
          <div className="md-step-bar-left"></div>
          <div className="md-step-bar-right"></div>
        </div>
      </div>
      {spinner}
      <Dialog
        open={open}
        disableBackdropClick
        disableEscapeKeyDown
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle
          id="form-dialog-title"
          style={{ backgroundColor: "#da3422", color: "white", width: "300px" }}
        >
          <ErrorOutlineOutlinedIcon style={{ marginBottom: "-4px" }} /> Contact
          Details
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            * The following fields are required
            <br />
            <span style={{ color: "black" }}>* Full Name</span>
            <br />
            <span style={{ color: "black" }}>* Mobile Phone</span>
            <br />
            <span style={{ color: "black" }}>* Email</span>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Dismiss
          </Button>
          <Button onClick={() => {}} color="primary"></Button>
        </DialogActions>
      </Dialog>
      <div className="main-content-group">
        <FormHelperText style={{ marginBottom: "-15px" }}>Name</FormHelperText>
        <TextField
          label="Full Name"
          name="firstName"
          value={firstName}
          onChange={setForm}
          margin="normal"
          variant="outlined"
          autoComplete="off"
          required
          fullWidth
        />
        {/* <TextField
          label="Last Name"
          name="lastName"
          value={lastName}
          onChange={setForm}
          margin="normal"
          variant="outlined"
          autoComplete="off"
          required
          fullWidth
        /> */}
        <FormHelperText>Mobile Phone*</FormHelperText>
        <MaskedInput
          mask={[
            "(",
            /[1-9]/,
            /\d/,
            /\d/,
            ")",
            " ",
            /\d/,
            /\d/,
            /\d/,
            "-",
            /\d/,
            /\d/,
            /\d/,
            /\d/,
          ]}
          label="Phone"
          name="customerPhone"
          className="phone-input"
          placeholder="(201) 555-5555 *"
          value={customerPhone}
          onChange={setForm}
          type="text"
          guide={false}
          required
        ></MaskedInput>

        <TextField
          label="Email Address"
          name="emailAddress"
          value={emailAddress}
          onChange={setForm}
          margin="normal"
          variant="outlined"
          autoComplete="off"
          type="email"
          onKeyUp={validateEmail}
          required
          fullWidth
        />
        <span style={{
          fontWeight: 'bold',
          color: 'red',
        }}>{emailError}</span>
      </div>

      <Button
        color="default"
        className="left-btn-contact"
        variant="outlined"
        style={{ marginBottom: "1rem", marginRight: "1rem", width: "45%" }}
        onClick={() => {
          showSpinner();
          setTimeout(() => hideSpinner(), 500);
          setTimeout(() => navigation.previous(), 500);
          executeScroll();
        }}
      >
        Back
      </Button>
      <Button
        id="nextBtn"
        color="primary"
        className="right-btn-contact"
        variant="contained"
        style={{ marginBottom: "1rem", width: "45%" }}
        onClick={() => {
          handleClickOpen();
          validateContactDetails();
          executeScroll();
        }}
      >
        Next
      </Button>
      {/* {spinner} */}
    </div>
  );
};