
  import firebase from 'firebase/app';
  import 'firebase/storage';
  import 'firebase/firestore';

  // Your web app's Firebase configuration
  var firebaseConfig = {
    apiKey: "AIzaSyBOnCVIszKaF-KEjXrYDd20_ELPODFPNbQ",
    authDomain: "speedpark-3c9d3.firebaseapp.com",
    databaseURL: "https://speedpark-3c9d3.firebaseio.com",
    projectId: "speedpark-3c9d3",
    storageBucket: "speedpark-3c9d3.appspot.com",
    messagingSenderId: "722719976302",
    appId: "1:722719976302:web:b3639df492c165fd",
    measurementId: "G-HGLVCZS0EX"
  };
  // Initialize Firebase
  firebase.initializeApp(firebaseConfig);

  const projectStorage = firebase.storage();
  const projectFirestore = firebase.firestore();

  export { projectStorage, projectFirestore };